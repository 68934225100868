import { Box, Flex, Img, Text } from '@chakra-ui/react';
import cidadeInteligenteLogo from '../../assets/cidadeInteligenteLogo.png';

import { APP_VERSION } from '../../version';

function Footer() {
  
  return (
    <Flex
      as="footer"
      bgColor="#407494"
      px="30px"
      w="100%"
      height="96px"
      justifyContent="space-between"
      alignItems="center"
      position="fixed"
      bottom="0"
      left="0"
      zIndex="1000"
    >
      <Box>
        <Text color={'#fff'}>
          <Img mt={'4px'} width={'25px'} src={cidadeInteligenteLogo} />
        </Text>
      </Box>
      <Box flex="1" textAlign="center">
        <Text ml={'90px'} color={'#fff'}>
          Desenvolvido por Eicon Controles Inteligentes
        </Text>
      </Box>
      <Box>
        <Text color={'#fff'}>
          {APP_VERSION}
        </Text>
      </Box>
    </Flex>
  );
}

export default Footer;
