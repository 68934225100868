import { Box, Text, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react'

function SocialMediaTrafic() {
  return (
    <>
      <Box borderRadius="4px" border="1px solid #EAEDF3" bgColor="#fff">
        <Box padding="20px 25px" borderBottom=" 1px solid #EAEDF3">
          <Text color="#3E3F42" fontSize="16px" fontWeight="600">
            Posts Mais Visitados
          </Text>
        </Box>
        <Table>
          {' '}
          <Thead>
            <Tr color="#9EA0A5">
              <Th color="#9EA0A5">Postagem</Th>
              <Th color="#9EA0A5">Visitantes</Th>
            </Tr>
          </Thead>
          <Tbody></Tbody>
          <Tr>
            <Td
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              pr="0"
            >
              <Text color="#3E3F42">Criação de mais Creches</Text>
              {/* <IconButton bgColor="#fff" aria-label="Dowload">
                <Icon
                  fontSize="26px"
                  mx="16px"
                  color="#9EA0A5"
                  as={RiShareBoxLine}
                />
              </IconButton> */}
            </Td>
            <Td pr="0">
              <Text color="#3E3F42">4.000</Text>
            </Td>
          </Tr>
        </Table>
      </Box>
    </>
  )
}

export default SocialMediaTrafic
