import { SimpleGrid, Box, Heading } from '@chakra-ui/react'
import PreviewCard from '../PreviewCard'
import ScheduleOrpublish from './publicContent/scheduleOrpublish'
import React, { useState } from 'react';

interface Banner {
  seq_conteúdo: number;
  nom_publicacao: string;
  bin_publicacao: string;
  dsc_publicacao: string;
}

function VisualizeContent() {
  return (
    <Box mb="55px">
      <Heading
        fontWeight="600"
        fontSize="21px"
        color="#5f4a4a"
        w="100%"
        mb="24px"
      >
        Visualize como sua publicação aparecerá
      </Heading>

      <SimpleGrid
        gridTemplateColumns="repeat(4, 5fr)"
        w="100%"
        gap={6}
      >
        <PreviewCard />

        {/* <PreviewCard />

        <PreviewCard /> */}
      </SimpleGrid>
      {/* <ScheduleOrpublish /> */}
    </Box>
  );
}

export default VisualizeContent;
