import { IconProps, Icon } from '@chakra-ui/react'
const Profile = (props: IconProps) => (
  <Icon viewBox="0 0 27.4 27.4" {...props}>
    <g id="male" transform="translate(-63.856 -73.326)">
      <circle
        id="Elipse_65"
        data-name="Elipse 65"
        cx={13}
        cy={13}
        r={13}
        transform="translate(64.556 74.026)"
        fill="none"
        stroke={props.stroke as string}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.4}
      />
      <path
        id="Caminho_67968"
        data-name="Caminho 67968"
        d="M93.075,100.5s-3.991-.459-4.7-1.281c-.4-.46,0-2.135,0-2.135.854-.427,1.495-2.092,1.708-2.989.189-.8.332-2.172.427-2.989a7.653,7.653,0,0,0,0-2.135c-.265-1.97-2.288-3.416-4.7-3.416s-4.433,1.446-4.7,3.416a7.636,7.636,0,0,0,0,2.135c.094.817.237,2.189.427,2.989.213.9.854,2.562,1.708,2.989,0,0,.4,1.675,0,2.135-.707.822-4.7,1.281-4.7,1.281"
        transform="translate(-8.022 -6.876)"
        fill="none"
        stroke={props.stroke as string}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.4}
      />
    </g>
  </Icon>
)

export default Profile
