export const getProfilesMock = () =>
  new Promise((resolve) => {
    resolve({
      data: [
        {
          name: 'Maria',
          ocupation: 'Arquiteta',
          posts: 15,
          actions: 10,
          urlAvatar: 'https://picsum.photos/id/237/200/300',
          imgsProfile: [
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300'
          ]
        },
        {
          name: 'Pedro',
          ocupation: 'Desenvolvedor',
          posts: 15,
          actions: 10,
          urlAvatar: 'https://picsum.photos/200/300',
          imgsProfile: [
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300',
            'https://picsum.photos/200/300'
          ]
        }
      ]
    })
  })
