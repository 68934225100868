import { IconProps, Icon } from '@chakra-ui/react'
const Library = (props: IconProps) => (
  <Icon viewBox="0 0 26.404 19.426" {...props}>
    <g id="picture" transform="translate(-965.4 -702.4)">
      <g
        id="Grupo_23031"
        data-name="Grupo 23031"
        transform="translate(969.125 705.896)"
      >
        <circle
          id="Elipse_66"
          data-name="Elipse 66"
          cx={2.3}
          cy={2.3}
          r={2.3}
          transform="translate(14.506 0)"
          fill="none"
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.2}
        />
        <path
          id="Caminho_67969"
          data-name="Caminho 67969"
          d="M990,764.728,995.728,759l7.811,7.29"
          transform="translate(-990 -754.605)"
          fill="none"
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.2}
        />
        <path
          id="Caminho_67970"
          data-name="Caminho 67970"
          d="M1095.334,793.13,1088.6,787l-2.6,2.6"
          transform="translate(-1073.502 -778.96)"
          fill="none"
          stroke={props.stroke as string}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.2}
        />
      </g>
      <path
        id="Caminho_67971"
        data-name="Caminho 67971"
        d="M989.451,721.226H967.545a1.523,1.523,0,0,1-1.545-1.5V704.5a1.523,1.523,0,0,1,1.545-1.5h21.905A1.523,1.523,0,0,1,991,704.5v15.227A1.523,1.523,0,0,1,989.451,721.226Z"
        fill="none"
        stroke={props.stroke as string}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.2}
      />
    </g>
  </Icon>
)

export default Library
