import { MdEdit } from 'react-icons/md'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
  IconButton
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

type User = {
  dscPerfilAcesso: string
  codPerfilAcesso: string
  ativo: number
}

interface TabelaUsuarioProps {
  listaUsuario: User[]
  listaVazia: string;
}

const TabelaUsuario = ({ listaUsuario, listaVazia }: TabelaUsuarioProps) => {
  const navigate = useNavigate();

  const renderRows = () =>
    listaUsuario.length > 0 ? (
    listaUsuario.map((usuario) => (
      <Tr key={usuario.codPerfilAcesso}>
        <Td>{usuario.codPerfilAcesso}</Td>
        <Td>{usuario.dscPerfilAcesso}</Td>
        <Td>{usuario.ativo == 1 ? 'Ativo' : 'Inativo'}</Td>
        <Td>
          <IconButton aria-label="Botão Editar" bgColor="#fff">
            <Icon 
              as={MdEdit} 
              fontSize="24px" 
              color="#1789FC" 
              onClick={() => navigate(`editar-usuario/${usuario.codPerfilAcesso}`)}
              />
          </IconButton>
        </Td>
      </Tr>
    ))
  ) : (
    <Tr>
      <Td colSpan={5} textAlign="center">{listaVazia}</Td>
    </Tr>
  );

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Código</Th>
            <Th>Perfil</Th>
            <Th>Situação</Th>
            <Th>Editar</Th>
          </Tr>
        </Thead>
        <Tbody>{renderRows()}</Tbody>
      </Table>
    </TableContainer>
  )
}

export default TabelaUsuario
