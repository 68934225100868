import { Flex, Heading } from '@chakra-ui/react'

import SettingsBreadcrumb from '../../components/SettingsBreadcrumb'
import withAuth from '../../contexts/AuthContext/withAuth'
import GridSettings from './components/GridConfiguracao'

const Settings = () => {
  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        mb="20px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Configuração
      </Heading>
      <GridSettings />
    </Flex>
  )
}

export default withAuth(Settings)
