import { useState, createContext, ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api';
import { auth } from '../../api/auth';

interface AuthContextType {
  login: (username: string, password: string) => Promise<{ error: boolean, accessToken?: string, profileName?: string, data?: string }>;
  logout: () => void;
  token: string;
  profileName?: string;
}

export const AuthenticationContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string>('');
  const [profileName, setProfileName] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedProfileName = localStorage.getItem('profileName');

    if (storedToken) {
      setToken(storedToken);
      api.defaults.headers.Authorization = `Bearer ${storedToken}`;
    }

    if (storedProfileName) {
      setProfileName(storedProfileName);
    }
  }, []);

  const login = async (username: string, password: string) => {
    try {
      const { data } = await auth(username, password);
      const { accessToken, profileName } = data;

      localStorage.setItem('token', accessToken);
      localStorage.setItem('profileName', profileName || '');

      api.defaults.headers.Authorization = `Bearer ${accessToken}`;
      setToken(accessToken);
      setProfileName(profileName);

      return { error: false, accessToken, profileName };
    } catch (e: any) {
      console.error(e, 'error');
      if (e.response?.status === 404) {
        return { error: true, data: 'Usuário não encontrado' };
      } else if (e.response?.status === 401) {
        return { error: true, data: 'Senha incorreta' };
      } else {
        return { error: true, data: e.message };
      }
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('profileName');
    setToken('');
    setProfileName(undefined);
    navigate('/');
  };

  return (
    <AuthenticationContext.Provider value={{ login, logout, token, profileName }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
};

export default AuthProvider;
