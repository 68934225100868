import { Box } from '@chakra-ui/react'

import PostsTable from '../PostsTable'

function PostPerformance() {
  return (
    <Box border="1px solid #EAEDF3" bgColor="#fff" borderRadius="4px">
      <PostsTable />
    </Box>
  )
}

export default PostPerformance
