import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb'
import { Divider, Flex, Heading } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import PageTable from './Table/tablePagination'

const ManagePages = () => {
  const navigate = useNavigate();

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        mb={35}
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Gerenciar Páginas
      </Heading>
      <PageTable id={0} pageName={''} action={<div></div>} situation={1} data={[]} filter={''} register={0}/>
    </Flex >
  )
}

export default ManagePages;