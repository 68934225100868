import React from 'react';
import { Button, HStack, Icon, Text } from '@chakra-ui/react';
import { MdOutlineArrowBackIos } from 'react-icons/md';

interface PaginacaoProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Paginacao: React.FC<PaginacaoProps> = ({ currentPage, totalPages, onPageChange }) => {
  const handleFirstPage = () => onPageChange(1);
  const handlePreviousPage = () => onPageChange(Math.max(currentPage - 1, 1));
  const handleNextPage = () => onPageChange(Math.min(currentPage + 1, totalPages));
  const handleLastPage = () => onPageChange(totalPages);

  return (
    <HStack spacing={4} justify="center" mt={4}>
      <Button
        onClick={handleFirstPage}
        disabled={currentPage === 1}
        bgColor="transparent"
        size="sm"
      >
        <Icon as={MdOutlineArrowBackIos} fontSize="20px" />
      </Button>
      <Button
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
        bgColor="transparent"
        size="sm"
      >
        <Icon as={MdOutlineArrowBackIos} fontSize="15px" />
      </Button>
      <Text fontSize="sm">
        Página {currentPage} de {totalPages}
      </Text>
      <Button
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        bgColor="transparent"
        size="sm"
      >
        <Icon as={MdOutlineArrowBackIos} fontSize="15px" transform="rotate(180deg)" />
      </Button>
      <Button
        onClick={handleLastPage}
        disabled={currentPage === totalPages}
        bgColor="transparent"
        size="sm"
      >
        <Icon as={MdOutlineArrowBackIos} fontSize="20px" transform="rotate(180deg)" />
      </Button>
    </HStack>
  );
};

export default Paginacao;
