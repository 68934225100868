import {
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  ModalBody,
  ModalOverlay,
  Text
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface ModalLogoutProps {
  isOpen: boolean;
  children?: React.ReactNode;
  onClose: () => void;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
  icon?: IconType;
  title?: string;
}

export const ModalLogout = ({
  isOpen,
  onClose,
  children,
  onConfirm,
  confirmText = 'Confirmar',
  cancelText = 'OK',
  icon: IconComponent,
  title 
}: ModalLogoutProps) => {
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        marginTop={'200px'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        p="12px 16px"
      >
        <ModalBody display="flex" flexDirection="column" alignItems="center">
            {/* {IconComponent && (
              <IconComponent color="#1789FC" fontSize="80px" />
            )} */}
          {title && ( 
            <Text fontSize="lg" fontWeight='medium' mb="8px">
              {title}
            </Text>
          )}
          <Flex justifyContent="end" mt="15px">
            <Button
              width={'auto'}
              backgroundColor={'#1789FC'}
              color={'white'}
              m="6px"
              mt={'15px'}
              padding={'5px'}
              onClick={onClose}
              _focus={{
                boxShadow: 'none',
                outline: 'none'
              }}
              _hover={{
                backgroundColor: '#8ec2ed'
              }}
            >
              {cancelText}
            </Button>

            {onConfirm && (
              <Button
                width={'auto'}
                backgroundColor={'#1789FC'}
                color={'white'}
                m="6px"
                mt={'15px'}
                onClick={() => {
                  if (onConfirm) onConfirm();
                  onClose();
                }}
                _hover={{
                  backgroundColor: '#8ec2ed'
                }}
              >
                {confirmText}
              </Button>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
