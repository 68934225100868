import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Text, Stack, HStack, RadioGroup, Radio, Icon, FormControl, FormLabel, Input, Button } from '@chakra-ui/react';
import { MdHelpOutline } from 'react-icons/md';
import {
  ApiComunication,
  getComunication,
  registerComunication
} from '../../../api/comunication';
import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb';
import { ModalPopUp } from './modal';

const Comunication = () => {
  const [typeOfService, setTypeOfService] = useState('none');
  const [sitServidor, setSitServidor] = useState<number>(0);
  const [isOpenTipoEmail, setIsOpenTipoEmail] = useState(false);
  const [isOpenEnderecoServidor, setIsOpenEnderecoServidor] = useState(false);
  const [isOpenPortaServidor, setIsOpenPortaServidor] = useState(false);
  const [isOpenUsuarioServidor, setIsOpenUsuarioServidor] = useState(false);
  const [isOpenSenhaServidor, setIsOpenSenhaServidor] = useState(false);
  const [isOpenRemetente, setIsOpenRemetente] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false);

  const navigate = useNavigate();

  const [formValues, setFormValues] = useState({
    serverAddress: { value: '', error: false },
    serverUser: { value: '', error: false },
    serverPort: { value: '', error: false },
    serverPassword: { value: '', error: false },
    senderIdentity: { value: '', error: false }
  });

  const handleFieldChange = (field: string, value: string) =>
    setFormValues(prev => ({
      ...prev,
      [field]: {
        error: !value.trim(),
        value
      }
    }));

  const buttonStyle: React.CSSProperties = {
    color: '#469ff8',
    position: 'relative',
    paddingLeft: '8px'
  };

  const iconStyle: React.CSSProperties = {
    position: 'relative',
    top: '3px'
  };

  const handleServiceChange = (value: string) => {
    setTypeOfService(value);
    const newStatus = value === 'smtp' ? 1 : 0;
    setSitServidor(newStatus);

    if (value === 'none') {
      updateComunication(newStatus);
    }
  };

  
  const updateComunication = async (status: number) => {
    try {
      const communicationData = {
        communicationData: [
          {
            dscServEndereco: formValues.serverAddress.value,
            dscServPorta: formValues.serverPort.value,
            dscUsuario: formValues.serverUser.value,
            dscSenha: formValues.serverPassword.value,
            dscRemetente: formValues.senderIdentity.value
          }
        ]
      };
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token não encontrado');
      }

      await registerComunication(communicationData, token);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      const communicationData = {
        communicationData: [
          {
            serverStatus: sitServidor,
            dscServEndereco: formValues.serverAddress.value,
            dscServPorta: formValues.serverPort.value,
            dscUsuario: formValues.serverUser.value,
            dscSenha: formValues.serverPassword.value,
            dscRemetente: formValues.senderIdentity.value
          }
        ]
      };
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token não encontrado');
      }

      localStorage.setItem('communicationData', JSON.stringify(communicationData));

      await registerComunication(communicationData, token);
      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error('Error during communication registration:', error);
      setIsFailureModalOpen(true);
    }
  };

  const getStoredCommunicationData = (): ApiComunication | null => {
    const storedDataString = localStorage.getItem('communicationData');

    if (storedDataString) {
      try {
        const storedData = JSON.parse(storedDataString);
        return storedData;
      } catch (error) {
        console.error('Error parsing localStorage data:', error);
      }
    }
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getComunication();
        console.log('firstData.serverStatus', res)
        if (res) {

          setFormValues({
            serverAddress: {
              value: res.dsc_serv_endereco || '',
              error: false
            },
            serverUser: {
              value: res.dsc_usuario || '',
              error: false
            },
            serverPort: {
              value: res.dsc_serv_porta || '',
              error: false
            },
            serverPassword: {
              value: res.dsc_senha || '',
              error: false
            },
            senderIdentity: {
              value: res.dsc_remetente || '',
              error: false
            }
          });
          
          const status = res.sit_servidor || 0;
          setSitServidor(status);
          setTypeOfService(status == "1" ? 'smtp' : 'none');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
        <SettingsBreadcrumb />
        <Heading mt="30px" fontWeight="600" fontSize="21px" w="100%">
          Serviço de Comunicação
        </Heading>
        <Text my="1rem">
          Tipo de servidor de e-mail
          <button onClick={() => setIsOpenTipoEmail(true)} style={buttonStyle}>
            <Icon fontSize={'18px'} as={MdHelpOutline} style={iconStyle} />
          </button>
          <ModalPopUp
            isOpen={isOpenTipoEmail}
            onClose={() => setIsOpenTipoEmail(false)}
          >
            Grupo de dados para conectar ao servidor de e-mail utilizado para
            enviar notificações aos usuários.
          </ModalPopUp>
        </Text>
        <Stack spacing={6}>
          <RadioGroup
            value={typeOfService}
            onChange={handleServiceChange}
          >
            <HStack spacing={3}>
              <Radio value="none">
                <Text>Não usamos</Text>
              </Radio>
              <Radio value="smtp">
                <Text>SMTP</Text>
              </Radio>
            </HStack>
          </RadioGroup>

          {typeOfService === 'smtp' && (
            <>
              <HStack spacing={6}>
                <FormControl>
                  <FormLabel mb="8px">
                    Endereço do servidor
                    <button
                      onClick={() => setIsOpenEnderecoServidor(true)}
                      style={buttonStyle}
                    >
                      <Icon
                        fontSize={'18px'}
                        as={MdHelpOutline}
                        style={iconStyle}
                      />
                    </button>
                    <ModalPopUp
                      isOpen={isOpenEnderecoServidor}
                      onClose={() => setIsOpenEnderecoServidor(false)}
                    >
                      IP/URL do servidor que oferece o serviço de e-mail.
                    </ModalPopUp>
                  </FormLabel>
                  <Input
                    aria-label="serverAddress"
                    value={formValues.serverAddress.value}
                    onChange={(e) =>
                      handleFieldChange('serverAddress', e.target.value)
                    }
                    size="sm"
                    borderColor={
                      formValues.serverAddress.error ? 'red.500' : 'gray.300'
                    }
                    _placeholder={{
                      color: 'gray.500'
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mb="8px">
                    Porta do servidor
                    <button
                      onClick={() => setIsOpenPortaServidor(true)}
                      style={buttonStyle}
                    >
                      <Icon
                        fontSize={'18px'}
                        as={MdHelpOutline}
                        style={iconStyle}
                      />
                    </button>
                    <ModalPopUp
                      isOpen={isOpenPortaServidor}
                      onClose={() => setIsOpenPortaServidor(false)}
                    >
                      Porta de conexão para o serviço de e-mail.
                    </ModalPopUp>
                  </FormLabel>
                  <Input
                    aria-label="serverPort"
                    value={formValues.serverPort.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        if (value.length <= 3) {
                          handleFieldChange('serverPort', value);
                        }
                      }
                    }}
                    size="sm"
                    borderColor={
                      formValues.serverPort.error ? 'red.500' : 'gray.300'
                    }
                    _placeholder={{
                      color: 'gray.500'
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mb="8px">
                    Usuário para autenticar
                    <button
                      onClick={() => setIsOpenUsuarioServidor(true)}
                      style={buttonStyle}
                    >
                      <Icon
                        fontSize={'18px'}
                        as={MdHelpOutline}
                        style={iconStyle}
                      />
                    </button>
                    <ModalPopUp
                      isOpen={isOpenUsuarioServidor}
                      onClose={() => setIsOpenUsuarioServidor(false)}
                    >
                      Usuário para acesso ao serviço de e-mail.
                    </ModalPopUp>
                  </FormLabel>
                  <Input
                    aria-label="serverUser"
                    value={formValues.serverUser.value}
                    onChange={(e) =>
                      handleFieldChange('serverUser', e.target.value)
                    }
                    size="sm"
                    borderColor={
                      formValues.serverUser.error ? 'red.500' : 'gray.300'
                    }
                    _placeholder={{
                      color: 'gray.500'
                    }}
                  />
                </FormControl>
              </HStack>
              <HStack spacing={6}>
                <FormControl>
                  <FormLabel mb="8px">
                    Senha do usuário para autenticar
                    <button
                      onClick={() => setIsOpenSenhaServidor(true)}
                      style={buttonStyle}
                    >
                      <Icon
                        fontSize={'18px'}
                        as={MdHelpOutline}
                        style={iconStyle}
                      />
                    </button>
                    <ModalPopUp
                      isOpen={isOpenSenhaServidor}
                      onClose={() => setIsOpenSenhaServidor(false)}
                    >
                      Senha do usuário para acesso ao serviço de e-mail.
                    </ModalPopUp>
                  </FormLabel>
                  <Input
                    aria-label="serverPassword"
                    value={formValues.serverPassword.value}
                    onChange={(e) =>
                      handleFieldChange('serverPassword', e.target.value)
                    }
                    size="sm"
                    type="password"
                    borderColor={
                      formValues.serverPassword.error ? 'red.500' : 'gray.300'
                    }
                    _placeholder={{
                      color: 'gray.500'
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel mb="8px">
                    Identificação do remetente
                    <button
                      onClick={() => setIsOpenRemetente(true)}
                      style={buttonStyle}
                    >
                      <Icon
                        fontSize={'18px'}
                        as={MdHelpOutline}
                        style={iconStyle}
                      />
                    </button>
                    <ModalPopUp
                      isOpen={isOpenRemetente}
                      onClose={() => setIsOpenRemetente(false)}
                    >
                      Endereço de e-mail irá aparecer para o destinatário do
                      e-mail. Exemplo: naoresponda@tecnogroup.com.br
                    </ModalPopUp>
                  </FormLabel>
                  <Input
                    aria-label="senderIdentity"
                    value={formValues.senderIdentity.value}
                    onChange={(e) =>
                      handleFieldChange('senderIdentity', e.target.value)
                    }
                    size="sm"
                    borderColor={
                      formValues.senderIdentity.error ? 'red.500' : 'gray.300'
                    }
                    _placeholder={{
                      color: 'gray.500'
                    }}
                  />
                </FormControl>
              </HStack>
              <HStack align="flex-end" justifyContent="flex-end" w="100%">
                <Button
                  fontWeight="600"
                  onClick={() => {
                    navigate('/cms/settings');
                  }}
                >
                  Desistir
                </Button>
                <Button
                  color="#FFF"
                  bgColor="#1665D8"
                  fontWeight="600"
                  onClick={handleSubmit}
                  isDisabled={Object.values(formValues).some(
                    (field) => !field.value.trim()
                  )}
                >
                  Salvar alterações
                </Button>
              </HStack>
              <ModalPopUp
                isOpen={isSuccessModalOpen}
                onClose={() => {
                  setIsSuccessModalOpen(false);
                  navigate('/cms/settings');
                }}
              >
                Serviço de comunicação cadastrado com sucesso!
              </ModalPopUp>
              <ModalPopUp
                isOpen={isFailureModalOpen}
                onClose={() => setIsFailureModalOpen(false)}
              >
                Falha ao salvar os dados. Por favor, tente novamente.
              </ModalPopUp>
            </>
          )}
        </Stack>
      </Flex>
    </>
  );
}

export default Comunication;
