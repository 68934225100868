import React from 'react'

import {
  Box,
  Container,
  Button,
  Flex,
  Circle,
  Text,
  Grid,
  GridItem,
  Image
} from '@chakra-ui/react'

export interface Props {
  name: string
  ocupation: string
  posts: string | number
  actions: string | number
  urlAvatar?: string
  imgsProfile: string[]
}

const Card = ({
  name,
  ocupation,
  posts,
  actions,
  urlAvatar,
  imgsProfile
}: Props) => {
  return (
    <Box
      border="1px solid #EAEDF3"
      boxShadow="0px 1px 3px #0000000A"
      borderRadius="4px"
      height="225px"
      width="100%"
      bgColor="#fff"
    >
      <Container
        display="flex"
        flexDirection="column"
        justifyContent="center"
        p="16px"
      >
        <Flex margin="10px">
          {urlAvatar ? (
            <Circle size="54px" marginRight="16px" backgroundImage={urlAvatar}>
              <Image
                boxSize="100%"
                borderRadius="full"
                objectFit="cover"
                src={urlAvatar}
                alt="Dan Abramov"
              />
            </Circle>
          ) : (
            <Circle border="1px solid #707070" size="54px" marginRight="16px" />
          )}
          <Flex flexDirection="column">
            <Text fontSize="12px" fontWeight="600" color="#3E3F42">
              {name}
            </Text>
            <Text
              fontSize="10px"
              fontWeight="600"
              color="#9EA0A5"
              margin="4px 0"
            >
              {ocupation}
            </Text>
            <Text fontSize="10px" fontWeight="600" color="#9EA0A5">
              {posts} postagens - {actions} ações
            </Text>
          </Flex>
        </Flex>
        <Grid margin="4px 10px" gridTemplateColumns="repeat(3, 1fr)" gap="12px">
          {imgsProfile.map((i, index) => (
            <GridItem
              key={index}
              colSpan={1}
              w="100%"
              height="56px"
              borderRadius="4px"
            >
              <Image
                boxSize="100%"
                borderRadius="4px"
                objectFit="cover"
                src={i}
              />
            </GridItem>
          ))}
        </Grid>
      </Container>
      <Button
        borderTop="1px solid #EAEDF3"
        textTransform="uppercase"
        color="#9EA0A5"
        fontWeight="600"
        variant="link"
        w="100%"
        p="16px"
        fontSize="12px"
      >
        Enviar Mensagem
      </Button>
    </Box>
  )
}

export default Card
