import React, { useState, useEffect } from 'react'

import {
  Box,
  Grid,
  GridItem,
  List,
  ListItem,
  Text,
  Flex
} from '@chakra-ui/react'

import api from './api'
import { Day, Event } from './types'
import withAuth from '../../contexts/AuthContext/withAuth'

const weekDays = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB']

function Calendar() {
  const [cells, setCells] = useState<Array<Day>>([])
  const [month, setMonth] = useState(4)
  const [year, setYear] = useState(2022)
  const [calendar, setCalendar] = useState({
    daysNumber: 0,
    dayOfTheWeek: 1,
    daysNumberLastMonth: 0
  })

  useEffect(() => {
    const daysNumber = new Date(year, month, 0).getDate()
    const daysNumberLastMonth = new Date(year, month - 1, 0).getDate()
    const dayOfTheWeek = new Date(year, month - 1, 1).getDay()

    const events = api.getCalendar().data

    setCalendar({ daysNumber, dayOfTheWeek, daysNumberLastMonth })
    const items = [] as Array<Day>

    for (let i = dayOfTheWeek - 1; i >= 0; i--) {
      items.push({ day: daysNumberLastMonth - i, events: [] })
    }

    for (let i = 1; i <= daysNumber; i++) {
      const hasMultipleEvents = events.reduce(
        (acc, item) => (item.day === i.toString() ? [...acc, item] : [...acc]),
        [] as Event[]
      )

      items.push({ day: i, events: hasMultipleEvents })
    }

    for (let i = 1; i <= 35 - items.length; i++) {
      items.push({ day: i, events: [] })
    }
    setCells(items)
  }, [month, year])

  return (
    <Flex w="100%" h="100%" p="20px" m="0">
      <Box
        color="#9EA0A5"
        border="1px solid #EAEDF3"
        boxShadow="0px 1px 3px #000000;"
        w="100%"
        fontWeight="600"
        bgColor="#FFF"
        borderRadius="4px"
      >
        <Grid w="100%" gridTemplateColumns="repeat(7, 1.75fr)" p="16px 0">
          {weekDays.map((day, index) => (
            <GridItem justifySelf={'center'} key={index}>
              <Text>{day}</Text>
            </GridItem>
          ))}
        </Grid>
        <Grid
          gridTemplateColumns="repeat(7, 1.75fr)"
          gridTemplateRows="repeat(5, 2.4fr)"
          border="1px solid #EAEDF3"
          w="100%"
        >
          {cells.map((i, index) => (
            <GridItem key={index} maxWidth="200px">
              <Box w="100%" h="100%" border="1px solid #EAEDF3">
                <Text p="16px">{i.day}</Text>
                <List>
                  {i.events.map((event, index) => (
                    <ListItem
                      bgColor="#E7EFFB"
                      color="#236EDA"
                      fontWeight="600"
                      p="16px"
                      key={index}
                    >
                      {event.title}
                    </ListItem>
                  ))}
                </List>
              </Box>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Flex>
  )
}

export default withAuth(Calendar)
