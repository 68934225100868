import { IconProps, Icon } from '@chakra-ui/react'
const Content = (props: IconProps) => (
  <Icon viewBox="0 0 27.561 27.561" {...props}>
    <g
      id="Grupo_23027"
      data-name="Grupo 23027"
      transform="translate(-209.85 -1173.934)"
    >
      <g
        id="Grupo_23002"
        data-name="Grupo 23002"
        transform="translate(210 1174.084)"
      >
        <g
          id="Grupo_22994"
          data-name="Grupo 22994"
          transform="translate(4.398 8.731)"
        >
          <path
            id="Caminho_67937"
            data-name="Caminho 67937"
            d="M91.147,96.734H87.4a1.109,1.109,0,0,1-1.108-1.107V91.879A1.109,1.109,0,0,1,87.4,90.771h3.749a1.109,1.109,0,0,1,1.107,1.108v3.749A1.108,1.108,0,0,1,91.147,96.734ZM87.4,91.623a.255.255,0,0,0-.256.256v3.749a.256.256,0,0,0,.256.256h3.749a.257.257,0,0,0,.256-.256V91.879a.256.256,0,0,0-.256-.256Z"
            transform="translate(-86.291 -90.771)"
            fill={props.stroke as string}
            stroke={props.stroke as string}
            strokeWidth={0.2}
          />
        </g>
        <g
          id="Grupo_22995"
          data-name="Grupo 22995"
          transform="translate(12.278 9.796)"
        >
          <path
            id="Caminho_67938"
            data-name="Caminho 67938"
            d="M101.288,92.844H95.751a.426.426,0,0,1,0-.852h5.538a.426.426,0,0,1,0,.852Z"
            transform="translate(-95.325 -91.992)"
            fill={props.stroke as string}
            stroke={props.stroke as string}
            strokeWidth={0.3}
          />
        </g>
        <g
          id="Grupo_22996"
          data-name="Grupo 22996"
          transform="translate(12.278 12.991)"
        >
          <path
            id="Caminho_67939"
            data-name="Caminho 67939"
            d="M101.288,96.506H95.751a.426.426,0,0,1,0-.852h5.538a.426.426,0,0,1,0,.852Z"
            transform="translate(-95.325 -95.654)"
            fill={props.stroke as string}
            stroke={props.stroke as string}
            strokeWidth={0.3}
          />
        </g>
        <g
          id="Grupo_22997"
          data-name="Grupo 22997"
          transform="translate(4.748 16.398)"
        >
          <path
            id="Caminho_67940"
            data-name="Caminho 67940"
            d="M100.187,100.412H87.119a.426.426,0,0,1,0-.852h13.068a.426.426,0,0,1,0,.852Z"
            transform="translate(-86.693 -99.56)"
            fill={props.stroke as string}
            stroke={props.stroke as string}
            strokeWidth={0.3}
          />
        </g>
        <g
          id="Grupo_22998"
          data-name="Grupo 22998"
          transform="translate(4.611 19.807)"
        >
          <path
            id="Caminho_67941"
            data-name="Caminho 67941"
            d="M100.166,104.318h-13.2a.426.426,0,1,1,0-.851h13.2a.426.426,0,1,1,0,.851Z"
            transform="translate(-86.536 -103.467)"
            fill={props.stroke as string}
            stroke={props.stroke as string}
            strokeWidth={0.3}
          />
        </g>
        <g
          id="Grupo_22999"
          data-name="Grupo 22999"
          transform="translate(4.611 23.214)"
        >
          <path
            id="Caminho_67942"
            data-name="Caminho 67942"
            d="M100.166,108.225h-13.2a.426.426,0,0,1,0-.852h13.2a.426.426,0,0,1,0,.852Z"
            transform="translate(-86.536 -107.373)"
            fill={props.stroke as string}
            stroke={props.stroke as string}
            strokeWidth={0.3}
          />
        </g>
        <g
          id="Grupo_23000"
          data-name="Grupo 23000"
          transform="translate(24.279 12.778)"
        >
          <path
            id="Caminho_67943"
            data-name="Caminho 67943"
            d="M109.508,106.059a.425.425,0,0,1-.426-.426v-9.8a.426.426,0,0,1,.852,0v9.8A.426.426,0,0,1,109.508,106.059Z"
            transform="translate(-109.082 -95.41)"
            fill={props.stroke as string}
            stroke={props.stroke as string}
            strokeWidth={0.3}
          />
        </g>
        <path
          id="Caminho_67944"
          data-name="Caminho 67944"
          d="M108.085,85.873a.426.426,0,0,0-.427.426v19.168a1.7,1.7,0,1,1-3.407,0V82.891a2.132,2.132,0,0,0-2.129-2.129H83.379a2.132,2.132,0,0,0-2.129,2.129v22.15a2.986,2.986,0,0,0,2.982,2.982h21.723a2.558,2.558,0,0,0,2.556-2.556V86.3A.425.425,0,0,0,108.085,85.873Zm-23.854,21.3a2.131,2.131,0,0,1-2.129-2.129V82.891a1.28,1.28,0,0,1,1.277-1.278h18.742a1.28,1.28,0,0,1,1.278,1.278v22.576a2.537,2.537,0,0,0,.656,1.7Z"
          transform="translate(-81.25 -80.762)"
          fill={props.stroke as string}
          stroke="#165fce"
          strokeWidth={0.3}
        />
        <g
          id="Grupo_23001"
          data-name="Grupo 23001"
          transform="translate(2.837 2.791)"
        >
          <path
            id="Caminho_67945"
            data-name="Caminho 67945"
            d="M84.5,87.538v-2.8h.886l.445.861a8.153,8.153,0,0,1,.441.982h.012a10.755,10.755,0,0,1-.058-1.182v-.661h.687v2.8h-.79l-.478-.932c-.154-.3-.338-.674-.47-1.011H85.16c.021.379.029.8.029,1.256v.687Z"
            transform="translate(-84.502 -84.06)"
            fill={props.stroke as string}
          />
          <path
            id="Caminho_67946"
            data-name="Caminho 67946"
            d="M90.487,86.1a1.344,1.344,0,0,1-1.406,1.477,1.323,1.323,0,0,1-1.348-1.432,1.372,1.372,0,0,1,1.393-1.468A1.321,1.321,0,0,1,90.487,86.1Zm-1.959.029c0,.529.229.853.591.853s.574-.354.574-.862c0-.482-.216-.848-.583-.848S88.528,85.613,88.528,86.129Z"
            transform="translate(-84.914 -84.052)"
            fill={props.stroke as string}
          />
          <path
            id="Caminho_67947"
            data-name="Caminho 67947"
            d="M91.731,85.366h-.715v-.632h2.2v.632h-.728v2.171h-.753Z"
            transform="translate(-85.333 -84.06)"
            fill={props.stroke as string}
          />
          <path
            id="Caminho_67948"
            data-name="Caminho 67948"
            d="M94.684,84.635v2.8h-.753v-2.8Zm.374-.674-.5.524h-.6l.338-.524Z"
            transform="translate(-85.706 -83.961)"
            fill={props.stroke as string}
          />
          <path
            id="Caminho_67949"
            data-name="Caminho 67949"
            d="M97.466,87.46a1.786,1.786,0,0,1-.7.117,1.352,1.352,0,0,1-1.51-1.4,1.444,1.444,0,0,1,1.56-1.494,1.667,1.667,0,0,1,.7.126l-.146.591a1.351,1.351,0,0,0-.52-.1.828.828,0,0,0,.017,1.656,1.568,1.568,0,0,0,.5-.079Z"
            transform="translate(-85.874 -84.053)"
            fill={props.stroke as string}
          />
          <path
            id="Caminho_67950"
            data-name="Caminho 67950"
            d="M99.012,84.734v2.8h-.753v-2.8Z"
            transform="translate(-86.258 -84.06)"
            fill={props.stroke as string}
          />
          <path
            id="Caminho_67951"
            data-name="Caminho 67951"
            d="M100.421,86.889l-.158.649h-.778l.811-2.8h1l.837,2.8h-.811l-.179-.649Zm.62-.553-.129-.5c-.042-.158-.1-.391-.141-.553h-.009c-.041.162-.083.4-.12.553l-.12.5Z"
            transform="translate(-86.415 -84.06)"
            fill={props.stroke as string}
          />
          <path
            id="Caminho_67952"
            data-name="Caminho 67952"
            d="M102.971,86.782a1.692,1.692,0,0,0,.728.174c.257,0,.383-.087.383-.229s-.126-.216-.441-.325c-.475-.167-.79-.428-.79-.844,0-.5.42-.878,1.127-.878a1.8,1.8,0,0,1,.757.146l-.158.6a1.458,1.458,0,0,0-.607-.133c-.229,0-.354.084-.354.208,0,.141.15.2.5.329.5.187.728.457.728.849,0,.482-.374.894-1.194.894a1.976,1.976,0,0,1-.824-.171Z"
            transform="translate(-86.842 -84.053)"
            fill={props.stroke as string}
          />
        </g>
      </g>
    </g>
  </Icon>
)

export default Content
