import { Flex, Heading } from '@chakra-ui/react';
import SettingsBreadcrumb from '../../components/SettingsBreadcrumb';
import GridPublicacao from './GridPublicacao/gridPublicacao';
import './publicacao.scss';

const Publicacao = () => {
  return (
    <Flex
      p="40px" 
      bgColor="#fafafa" 
      direction="column">
      <SettingsBreadcrumb /> 
      <Heading
        mt="30px"
        mb="20px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Publicação
      </Heading>
    <GridPublicacao/>
    </Flex>
    
  );
};

export default Publicacao;
