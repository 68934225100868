import { Flex, Heading, Button, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Box, RadioGroup, Radio } from '@chakra-ui/react'; 
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb';
import CustomInput from '../../../../components/common/Input/input';
import { editarAplicativo } from '../../../../api/aplicativo';

function EditarAplicativo() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  const [formValues, setFormValues] = useState({
    dscAplicativo: { value: '', error: false },
    dscToken: { value: '', error: false },
    numRegistro: { value: 0, error: false }
  });

  const isFormValid = () => {
    const { dscAplicativo } = formValues;
    return dscAplicativo.value.trim().length >= 5;
  };

  const handleFieldChange = (field: any, value: any) => {
    setFormValues({
      ...formValues,
      [field]: { error: false, value }
    });
  };

  const handleSubmit = async () => {
    const { dscAplicativo, dscToken, numRegistro } = formValues;

    const params = {
      dscAplicativo: dscAplicativo.value.trim() !== '' ? dscAplicativo.value : null,
      dscToken: dscToken.value || null,
      numRegistro: numRegistro.value
    };
  
    try {
      await editarAplicativo(Number(id), params);
      setIsSuccessModalOpen(true);
    } catch (error) {
      setErrorMessage('Erro ao editar aplicativo. Tente novamente.');
      setIsErrorModalOpen(true);
    }
  };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" mb="20px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Editar Aplicativo
      </Heading>
      <div className="row mb-3">
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Descrição do Aplicativo</FormLabel>
            <CustomInput
              value={formValues.dscAplicativo.value}
              onChange={(e) => handleFieldChange('dscAplicativo', e.target.value)}
              maxLength={60}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Token</FormLabel>
            <CustomInput
              value={formValues.dscToken.value}
              onChange={(e) => handleFieldChange('dscToken', e.target.value)}
              maxLength={60}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Situação</FormLabel>
            <RadioGroup mt={3.5} onChange={(value) => handleFieldChange('numRegistro', Number(value))} value={formValues.numRegistro.value.toString()}>
              <Radio value="1" mr={5}>Ativo</Radio>
              <Radio value="0">Inativo</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <Box alignItems={'flex-end'}>
        <Button m="px" onClick={() => navigate(-1)}>
          Desistir
        </Button>
        <Button
          m="6px"
          color="#FFF"
          bgColor="#1665D8"
          _hover={{ opacity: 0.8 }}
          onClick={handleSubmit}
          isDisabled={!isFormValid()}
        >
          Editar Aplicativo
        </Button>
      </Box>

      <Modal isOpen={isSuccessModalOpen} onClose={() => {
        setIsSuccessModalOpen(false);
        navigate('/cms/noticia');
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sucesso!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Aplicativo editado com sucesso!</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => {
              setIsSuccessModalOpen(false);
              navigate('/cms/noticia');
            }}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atenção!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{errorMessage}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => setIsErrorModalOpen(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default EditarAplicativo;
