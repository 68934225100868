import { Flex, Heading, Button, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Box, RadioGroup, Radio } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb';
import CustomInput from '../../../../components/common/Input/input';
import * as api from '../../../../api/categoria';

interface Categoria {
  seqCategoria: number;
  dscCategoria: string;
  datRegistro: string;
  numRegistro: number;
}

function EditarCategoria() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  const [categoria, setCategoria] = useState<Categoria | null>(null);
  const [formValues, setFormValues] = useState({
    descricao: { value: '', error: false },
    numRegistro: { value: 0, error: false }
  });

    const fetchCategoria = async () => {
      if (!id) return;
  
      try {
        const data = await api.obterCategoria(Number(id));
        setCategoria(data);
      } catch (error) {
        console.error('Erro ao obter categoria:', error);
      }
    };
  
    useEffect(() => {
      fetchCategoria();
    }, [id]); 

  const isFormValid = () => {
    const { descricao } = formValues;
    return descricao.value.trim() && descricao.value.length >= 5;
  };

  const handleFieldChange = (field: any, value: any) => {
    setFormValues({
      ...formValues,
      [field]: { error: false, value }
    });
  };

  const handleSubmit = async () => {
    const { descricao, numRegistro } = formValues;
  
    const params = {
      descricao: descricao.value || null,
      numRegistro: numRegistro.value
    };
  
    try {
      await api.editarCategoria(Number(id), params);
      setIsSuccessModalOpen(true);
    } catch (error) {
      setErrorMessage('Erro ao editar categoria. Tente novamente.');
      setIsErrorModalOpen(true);
    }
  };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" mb="20px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Editar Categoria
      </Heading>
      <div className="row mb-3">
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Descrição</FormLabel>
            <CustomInput
              value={categoria ? categoria.dscCategoria : formValues.descricao.value}
              onChange={(e) => handleFieldChange('descricao', e.target.value)}
              maxLength={60}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Situação</FormLabel>
            <RadioGroup 
              mt={3.5} 
              onChange={(value) => handleFieldChange('numRegistro', Number(value))} 
              value={categoria ? categoria.numRegistro.toString() : formValues.numRegistro.value.toString()}
            >
              <Radio value="1" mr={5}>Ativo</Radio>
              <Radio value="0">Inativo</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <Box alignItems={'flex-end'}>
        <Button m="px" onClick={() => navigate(-1)}>
          Desistir
        </Button>
        <Button
          m="6px"
          color="#FFF"
          bgColor="#1665D8"
          _hover={{ opacity: 0.8 }}
          onClick={handleSubmit}
          isDisabled={!isFormValid()}
        >
          Editar Categoria
        </Button>
      </Box>

      <Modal isOpen={isSuccessModalOpen} onClose={() => {
        setIsSuccessModalOpen(false);
        navigate('/cms/noticia');
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sucesso!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Categoria editada com sucesso!</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => {
              setIsSuccessModalOpen(false);
              navigate('/cms/noticia');
            }}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atenção!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{errorMessage}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => setIsErrorModalOpen(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default EditarCategoria;
