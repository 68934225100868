import React from 'react';
import ReactDOM from 'react-dom';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxTreeGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxtreegrid';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { HashRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App';

const theme = createTheme();

ReactDOM.render(
  <Router>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);
