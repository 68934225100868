import * as React from 'react'

import { Box, Heading, Grid, GridItem } from '@chakra-ui/react'

import CardInfoDetail from './components/CardInfoDetail'
import DailyVisitors from './components/DailyVisitors'
import MostVisitPost from './components/MostVisitPost'
import PostPerformance from './components/PostPerformance'
import SocialMediaTrafic from './components/SocialMediaTrafic'
import withAuth from '../../contexts/AuthContext/withAuth'

function Reports() {
  return (
    <Box color="black" w="100%" h="10hv" p="40px" backgroundColor="#FBFBFD">
      <Heading mb="16px" color="#6F6F6F" fontWeight="600" fontSize="21px">
        Relatórios
      </Heading>
      <DailyVisitors />
      <Grid gridTemplateColumns="repeat(4, 3fr)" my="28px" gap={6}>
        <GridItem>
          <CardInfoDetail
            title="Usuarios em tempo real"
            value="56"
            percentage="9.8"
            isGrowing
          />
        </GridItem>
        <GridItem>
          <CardInfoDetail
            title="Usuarios em tempo real"
            value="56"
            percentage="9.8"
          />
        </GridItem>
      </Grid>
      <Box mb="28px">
        <PostPerformance />
      </Box>
      <Grid gridTemplateColumns="8fr 4fr" gap={6}>
        <GridItem>
          <MostVisitPost />
        </GridItem>
        <GridItem>
          <SocialMediaTrafic />
        </GridItem>
      </Grid>
    </Box>
  )
}

export default withAuth(Reports) 
