import React, { useState } from 'react';
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb';
import {
  Heading,
  Flex,
  GridItem,
  Input,
  Box,
  Button,
} from '@chakra-ui/react';
import TextEditor from '../../../../components/TextEditor/';
import { useNavigate, useParams } from 'react-router-dom';

export default function CreateText() {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [isMaxCharactersReached, setIsMaxCharactersReached] = useState(false);

  const handleTitleChange = (event: any) => {
    setTitle(event.target.value);
  };

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
    if (content.length > 10000) {
      setIsMaxCharactersReached(true);
    } else {
      setIsMaxCharactersReached(false);
    }
  };

  const handleSave = () => {
    if (title || editorContent) {
      console.log(`Titulo: ${title} Texto:${editorContent}`)
    }
  };

  const isSaveDisabled = editorContent.replace(/\s/g, '') === '';

  return (
    <Flex h="100%" p="40px" bgColor="#fafafa" direction="column">
      {/* <SettingsBreadcrumb /> */}
      <Heading fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Criar Texto
      </Heading>

      <Heading
        pt="24px"
        pb="32px"
        fontWeight="600"
        fontSize="20px"
        color="#1664D7"
      >
        {' '}
        Adicione um Título
      </Heading>
      <Input
        type="text"
        mb={30}
        value={title}
        placeholder={'Digite o Título'}
        onChange={handleTitleChange}
      />
      <GridItem>
        <TextEditor value={editorContent} onChange={handleEditorChange} />
      </GridItem>
      <Box mt="auto" ml="auto">
        <Button type="submit" m="px" onClick={() => navigate(-1)}>
          Cancelar
        </Button>
        <Button
          type="submit"
          m="6px"
          color="#FFF"
          bgColor="#1665D8"
          _hover={{ opacity: 0.8 }}
          onClick={() => {
            handleSave();
            navigate('/cms/content');
          }}  
          disabled={isSaveDisabled || isMaxCharactersReached || !title}
        >
          Salvar
        </Button>
      </Box>
    </Flex>
  );
}
