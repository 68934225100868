import { Flex, Heading, Button, Box, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import CustomInput from '../../../../components/common/Input/input'
import * as api from '../../../../api/usuario';

function AdicionarUsuario() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [formValues, setFormValues] = useState({
    dscNome: {
      value: '',
      error: false
    },
    dscCodigoUsuario: {
      value: '',
      error: false
    }
  });

  const navigate = useNavigate();

  const fetchLastCodigoUsuario = async () => {
    try {
      const response = await api.listarUsuarios(1, 10);
      const lastCodigo = response.formatoArquivos[response.formatoArquivos.length - 1].codPerfilAcesso;
      setFormValues(prevState => ({
        ...prevState,
        dscCodigoUsuario: {
          value: (lastCodigo + 1).toString(),
          error: false
        }
      }));
    } catch (error) {
      console.error("Erro ao obter o código de perfil", error);
      setErrorMessage('Erro ao obter o código de perfil.');
      setIsErrorModalOpen(true);
    }
  };

  useEffect(() => {
    fetchLastCodigoUsuario();
  }, []);

  const isFormValid = () => {
    const { dscNome } = formValues;
    return dscNome.value.trim() && dscNome.value.length >= 3;
  };

  const handleFieldChange = (field: string, value: string) =>
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    });

  const handleSubmit = async () => {
    const { dscNome } = formValues;
    const { dscCodigoUsuario } = formValues;

    const params = {
      dscNome: dscNome.value || null,
      dscCodigoUsuario: dscCodigoUsuario.value || null,
    };
    
    try {
      await api.inserirUsuario(params);
      setIsSuccessModalOpen(true);
    } catch (error) {
      setErrorMessage('Erro ao adicionar usuário. Tente novamente.');
      setIsErrorModalOpen(true);
    }
  };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        mb="20px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Novo Perfil de Usuário
      </Heading>
      <div className="row mb-3">
        <div className="col-md-3 mb-4">
          <FormControl>
            <FormLabel mb="8px">Código do perfil de acesso</FormLabel>
            <CustomInput
              isReadOnly
              value={formValues.dscCodigoUsuario.value}
              onChange={(e) => handleFieldChange('dscCodigoUsuario', e.target.value)}
              style={{ backgroundColor: '#f5f5f5', cursor: 'not-allowed' }}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Perfil</FormLabel>
            <CustomInput
              value={formValues.dscNome.value}
              onChange={(e) => handleFieldChange('dscNome', e.target.value)}
              minLength={3}
              maxLength={60}
            />
          </FormControl>
        </div>
      </div>

      <Box alignItems={'flex-end'}>
        <Button type="submit" m="px" onClick={() => navigate(-1)}>
          Desistir
        </Button>
        <Button
          type="submit"
          m="6px"
          color="#FFF"
          bgColor="#1665D8"
          _hover={{ opacity: 0.8 }}
          onClick={handleSubmit}
          isDisabled={!isFormValid()}
        >
          Criar o Perfil
        </Button>
      </Box>

      <Modal isOpen={isSuccessModalOpen} onClose={() => {
        setIsSuccessModalOpen(false);
        navigate(-1);
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sucesso!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Usuário adicionado com sucesso!</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => {
              setIsSuccessModalOpen(false);
              navigate(-1);
            }}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atenção!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{errorMessage}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => setIsErrorModalOpen(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default AdicionarUsuario;
