import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button
} from '@chakra-ui/react'
import TextEditor from '../TextEditor'


export interface ModaleditorProps {
  open: boolean
  onClose: () => void
}

const ModalEditor = ({ open, onClose }: ModaleditorProps) => {
  return (
    <Modal isOpen={open} onClose={() => {}} size="xl">
      <ModalContent>
        <ModalHeader>Modal Title</ModalHeader>
        <ModalBody>
         <TextEditor />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalEditor
