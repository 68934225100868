import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import CustomInput from '../../../components/common/Input/input';
import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb';
import { FaInfoCircle } from 'react-icons/fa';
import 'ckeditor5/ckeditor5.css';
import './inserir-banner.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Flex,
  Heading,
  Box,
  Button,
  FormLabel,
  FormControl,
  Select,
  Stack,
  Checkbox,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';
import { ImageDropzone } from '../../PublishContent/components/Banner/imageDropzone';
import { FileWithPath } from 'react-dropzone';
import * as api from '../../../api/banner';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const InserirBanner: React.FC = () => {
  const navigate = useNavigate();
  const today = new Date().toISOString().split('T')[0];

  const [formValues, setFormValues] = useState<{
    titulo: { value: string; error: boolean };
    data: { value: typeof today; error: boolean };
    dataFim: { value: ''; error: boolean };
    imagem: { value: string; error: boolean };
    urlBanner: { value: string; error: boolean };
    creditoAutorImagem: { value: string; error: boolean };
    ordemBanner: { value: any[]; error: boolean };
    categoriaId: { value: string; error: boolean };
    paginaId: { value: any[]; error: boolean };
  }>({
    titulo: { value: '', error: false },
    data: { value: today, error: false },
    dataFim: { value: '', error: false },
    imagem: { value: '', error: false },
    urlBanner: { value: '', error: false },
    creditoAutorImagem: { value: '', error: false },
    ordemBanner: { value: [], error: false },
    categoriaId: { value: '', error: false },
    paginaId: { value: [], error: false },
  });

  const [imageBase64, setImageBase64] = useState<string>('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [categorias, setCategorias] = useState<any[]>([]);
  const [paginas, setPaginas] = useState<any[]>([]);
  const [ordemValida, setOrdemValida] = useState(true);
  const [ordemRecomendada, setOrdemRecomendada] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPages, setSelectedPages] = useState<number[]>([]);
  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const data = await api.listarCategoriasAtivas();
        setCategorias(data);
      } catch (error) {
        console.error('Erro ao buscar categorias ativas:', error);
      }
    };

    fetchCategorias();
    fetchPaginas();
  }, []);

  useEffect(() => {
    const validarOrdem = async () => {
      if (formValues.ordemBanner.value.length === 0 || formValues.ordemBanner.value.some((item: any) => item.numOrdem === '')) {
        setOrdemValida(true);
        setOrdemRecomendada(null);
        return;
      }
  
      setLoading(true);
  
      try {
        const ordensExistentes = await Promise.all(
          formValues.ordemBanner.value.map((item: any) => {
            return api.validarOrdemDisponiveis(item.seqPagina);
          })
        );
  
        const ordensValidas = ordensExistentes.every((item: any) => item.valida);
  
        if (ordensValidas) {
          setOrdemValida(true);
          setOrdemRecomendada(null);
        } else {
          setOrdemValida(false);
  
          const seqPagina = ordensExistentes[0]?.seqPagina;
          if (seqPagina) {
            const recomendada = calcularOrdemRecomendada(ordensExistentes);
            const errorMessage = `A ordem fornecida não está disponível. É recomendado usar o número ${recomendada}.`;
  
            setErrorMessage(errorMessage);
            setOrdemRecomendada(recomendada);
            setIsErrorModalOpen(true);
          }
        }
      } catch (error) {
        console.error('Erro ao validar ordem:', error);
        setOrdemValida(false);
        setOrdemRecomendada(null);
        setErrorMessage('Houve um erro ao validar a ordem. Tente novamente mais tarde.');
        setIsErrorModalOpen(true);
      } finally {
        setLoading(false);
      }
    };
  
    if (formValues.ordemBanner.value.some((item: any) => /^\d+$/.test(item.numOrdem))) {
      validarOrdem();
    }
  }, [formValues.ordemBanner.value]);
  
  const fetchPaginas = async () => {
    setLoading(true);
    try {
      const response = await api.paginasAtivas();
      const data = await response.json();
      setPaginas(data);
    } catch (error) {
      console.error('Erro ao buscar páginas:', error);
    } finally {
      setLoading(false);
    }
  };

  const paginasMockadas = [
    { seqPagina: 1, nomPagina: "Secretaria de São Bernardo" },
    { seqPagina: 23, nomPagina: "Secretaria de Vila Prudente" },
    { seqPagina: 3, nomPagina: "Secretaria de São Mateus" },
    { seqPagina: 4, nomPagina: "Secretaria de Sacomã" },
    { seqPagina: 5, nomPagina: "Secretaria de aaaaaaaaaaa" },
    { seqPagina: 6, nomPagina: "Secretaria de teste" },
    { seqPagina: 7, nomPagina: "Secretaria de São Mateus" },
    { seqPagina: 8, nomPagina: "Secretaria de Sacomã" },
    { seqPagina: 9, nomPagina: "Secretaria de aaaaaaaaaaa" },
    { seqPagina: 10, nomPagina: "Secretaria de teste" },
    { seqPagina: 120, nomPagina: "2222222222222222222222222222222222222222" },
  ];

  const calcularOrdemRecomendada = (ordensExistentes: any) => {
    const ordens = ordensExistentes.map((item: any) => item.numOrdem);
    const maiorOrdem = Math.max(...ordens);
    const ordemRecomendada = ordens.includes(maiorOrdem) ? maiorOrdem + 1 : maiorOrdem;

    return ordemRecomendada;
  };

  const modalOrdem = () => {
    setLoading(true);
    setOrdemValida(false);
    setOrdemRecomendada(3);

    setModalMessage(`A ordem fornecida não está disponível. ${ordemRecomendada ? `É recomendado usar o número ${ordemRecomendada}.` : ''}`);
    setLoading(false);
    setIsModalOpen(true);
  };

  const handleFieldChange = (field: keyof typeof formValues, value: any) => {
    console.log(`Campo alterado: ${field}, Novo valor: ${value}`);
    setFormValues({ ...formValues, [field]: { value, error: false } });
  };

  const handleCheckboxChange = (seqPagina: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    setSelectedPages((prev) => {
      const updatedPages = isChecked ? [...prev, seqPagina] : prev.filter((id) => id !== seqPagina);
      return updatedPages;
    });

    setFormValues((prevValues: any) => {
      const updatedPaginaIdValue = isChecked
        ? [...prevValues.paginaId.value, seqPagina]
        : prevValues.paginaId.value.filter((id: number) => id !== seqPagina);

      let updatedOrdemValue = [...prevValues.ordemBanner.value];
      if (isChecked) {
        updatedOrdemValue.push({ seqPagina, numOrdem: '' });
      } else {
        updatedOrdemValue = updatedOrdemValue.filter((paginaOrdem: any) => paginaOrdem.seqPagina !== seqPagina);
      }

      return {
        ...prevValues,
        paginaId: { value: updatedPaginaIdValue },
        ordemBanner: { value: updatedOrdemValue },
      };
    });
  };

  const handleImageSelected = async (selected: FileWithPath | null) => {
    if (selected) {
      const image = await createImage(selected);
      const resizedImage = await convertTamanhoImagem(image, 1200, 400);

      if (resizedImage) {
        const base64Image = await convertBase64(resizedImage);
        setImageBase64(base64Image);
      } else {
        setImageBase64('');
        console.error('Erro ao redimensionar a imagem.');
      }
    } else {
      setImageBase64('');
    }
  };

  const createImage = (file: File) => {
    return new Promise<HTMLImageElement>((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => resolve(img);
    });
  };

  const convertTamanhoImagem = (image: HTMLImageElement, width: number, height: number) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    console.log(`Dimensões da imagem original: ${image.width}x${image.height}`);

    if (ctx) {
      ctx.drawImage(image, 0, 0, width, height);
    }

    return new Promise<Blob | null>((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const img = new Image();
          img.src = URL.createObjectURL(blob);
          img.onload = () => {
            console.log(`Dimensões da imagem redimensionada: ${img.width}x${img.height}`);
            resolve(blob);
          };
        } else {
          resolve(null);
        }
      }, 'image/jpeg');
    });
  };

  const convertBase64 = async (file: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const isFormValid = () => {
    const { titulo, data, creditoAutorImagem, ordemBanner, categoriaId, paginaId } = formValues;
    const isOrdemValida = ordemBanner.value.every((item: any) => item.numOrdem && item.numOrdem !== '0');

    const isValid = (
      titulo.value &&
      data.value &&
      creditoAutorImagem.value &&
      ordemBanner.value &&
      categoriaId.value &&
      (paginaId.value.length > 0) &&
      (ordemBanner.value.length > 0) &&
      imageBase64.trim() !== '' &&
      isOrdemValida
    );
    console.log("isFormValid:", {
      titulo: titulo.value,
      data: data.value,
      creditoAutorImagem: creditoAutorImagem.value,
      categoriaId: categoriaId.value,
      paginaId: paginaId.value.length,
      ordemBanner: ordemBanner.value.length,
      imageBase64: imageBase64.trim(),
      isOrdemValida
    });
    return isValid;
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { urlBanner, creditoAutorImagem, ordemBanner, dataFim, categoriaId, paginaId } = formValues;

    if (!ordemValida) {
      setErrorMessage('A ordem fornecida não é válida.');
      setIsErrorModalOpen(true);
    }

    const params = {
      codTipoPublicacao: 3,
      nomePublicacao: formValues.titulo.value || null,
      datPublicacao: formValues.data.value ? formValues.data.value : null,
      datDespublicar: dataFim.value ? formValues.dataFim.value : null,
      seqUsuario: 124,
      binPublicacao: imageBase64 || null,
      urlBin: urlBanner.value || null,
      dscCreditoImagem: creditoAutorImagem.value || null,
      seqCategoria: Number(categoriaId.value) || null,
      conteudoPaginaDTO: paginaId.value.map((id: number) => {
        const ordem = ordemBanner.value.find((item: any) => item.seqPagina === id);
        return {
          seqPagina: id,
          numOrdem: ordem ? ordem.numOrdem : null,
        };
      }),
    };

    try {
      await api.inserirBanner(params);
      setIsSuccessModalOpen(true);
    } catch (error) {
      setErrorMessage('Erro ao criar banner. Tente novamente.');
      setIsErrorModalOpen(true);
    }
  };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Criar Banner
      </Heading>
      <Box m="40px 0" w="100%">
        <form onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="col-md-6 mb-4">
              <FormControl isRequired isInvalid={formValues.titulo.error}>
                <FormLabel mb="8px">Categoria do Banner</FormLabel>
                <Select
                  placeholder='Selecione uma Categoria'
                  borderColor="gray.300"
                  _hover={{ borderColor: 'blue.500' }}
                  value={formValues.categoriaId.value}
                  onChange={(e) => handleFieldChange('categoriaId', e.target.value)}
                >
                  {categorias.length > 0 ? (
                    categorias.map(categoria => (
                      <option key={categoria.id} value={categoria.id}>
                        {categoria.nome}
                      </option>
                    ))
                  ) : (
                    <option value="1">Categoria Mockada</option>
                  )}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 mb-4">
              <FormControl isRequired isInvalid={formValues.titulo.error}>
                <FormLabel mb="8px">Título da Publicação</FormLabel>
                <CustomInput
                  value={formValues.titulo.value}
                  onChange={(e: any) => handleFieldChange('titulo', e.target.value)}
                />
              </FormControl>
            </div>

            <div className="col-md-6 mb-4">
              <FormControl>
                <FormLabel mb="8px">URL para tornar banner clicável</FormLabel>
                <CustomInput
                  value={formValues.urlBanner.value}
                  onChange={(e) => handleFieldChange('urlBanner', e.target.value)}
                />
              </FormControl>
            </div>

            <div className="col-md-3 mb-4">
              <FormControl isRequired>
                <FormLabel mb="8px">Data para Publicação</FormLabel>
                <CustomInput
                  id="publication-date"
                  type="date"
                  value={formValues.data.value}
                  onChange={(e) => handleFieldChange('data', e.target.value)}
                  min={today}
                />
              </FormControl>
            </div>
            <div className="col-md-3 mb-4">
              <FormControl>
                <FormLabel mb="8px">Data fim para Publicação</FormLabel>
                <CustomInput
                  id="publication-date"
                  type="date"
                  value={formValues.dataFim.value}
                  onChange={(e) => handleFieldChange('dataFim', e.target.value)}
                  min={today}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4">
              <FormControl isRequired>
                <FormLabel mb="8px">Imagem do Banner</FormLabel>
                <ImageDropzone
                  onImageSelected={handleImageSelected}
                  base64={imageBase64}
                  width={1200}
                  height={400}
                  uploadMessage={`Arraste a imagem do banner aqui ou clique para selecionar. <br/>(1200x400 pixels)`}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-4">
              <FormControl>
                <Flex justify="space-between" align="center" mb="8px">
                  <FormLabel mb="8px">
                    Escolha em quais páginas o banner deve aparecer <span style={{ color: 'red' }}>*</span>
                  </FormLabel>
                  <FormLabel mb="8px">
                    Ordem de exibição <span style={{ color: 'red' }}>*</span>
                    <Tooltip label="Ordem a ser utilizada para exibir o banner nas páginas." aria-label="A informação da ordem de exibição">
                      <IconButton
                        mb={'8px'}
                        icon={<FaInfoCircle />}
                        variant="link"
                        aria-label="Informação"
                        size="sm"
                      />
                    </Tooltip>
                  </FormLabel>
                </Flex>

                <div className="sidebar-checkbox-pagina">
                  <Stack spacing={5}>
                    {paginasMockadas.length > 0 ? (
                      paginasMockadas.map((pagina) => (
                        <Flex key={pagina.seqPagina} align="center" justify="space-between" direction={{ base: 'column', md: 'row' }}>
                          <Checkbox
                            className="checkPaginas"
                            isChecked={selectedPages.includes(pagina.seqPagina)}
                            onChange={(e) => handleCheckboxChange(pagina.seqPagina, e)}
                          >
                            {pagina.nomPagina}
                          </Checkbox>

                          <div>
                            <CustomInput
                              className="input-ordem"
                              isDisabled={!selectedPages.includes(pagina.seqPagina)}
                              value={formValues.ordemBanner.value.find((item: any) => item.seqPagina === pagina.seqPagina)?.numOrdem || ''}
                              onChange={(e) => {
                                const value = e.target.value;

                                if (value === '') {
                                  setFormValues((prevValues: any) => {
                                    const updatedOrdemValue = prevValues.ordemBanner.value.filter((item: any) => item.seqPagina !== pagina.seqPagina);
                                    return {
                                      ...prevValues,
                                      ordemBanner: { value: updatedOrdemValue },
                                    };
                                  });
                                } else if (/^\d+$/.test(value) && value !== '0') {
                                  setFormValues((prevValues: any) => {
                                    const updatedOrdemValue = [...prevValues.ordemBanner.value];
                                    const existingOrderIndex = updatedOrdemValue.findIndex((item: any) => item.seqPagina === pagina.seqPagina);

                                    if (existingOrderIndex > -1) {
                                      updatedOrdemValue[existingOrderIndex] = { seqPagina: pagina.seqPagina, numOrdem: value };
                                    } else {
                                      updatedOrdemValue.push({ seqPagina: pagina.seqPagina, numOrdem: value });
                                    }

                                    return {
                                      ...prevValues,
                                      ordemBanner: { value: updatedOrdemValue },
                                    };
                                  });
                                }
                              }}
                              placeholder="Ordem"
                            />

                          </div>
                        </Flex>
                      ))
                    ) : (
                      <p>Não há páginas disponíveis.</p>
                    )}
                  </Stack>
                </div>
              </FormControl>

            </div>
            <div className="col-md-6 mb-4">
              <FormControl isRequired>
                <FormLabel mb="8px">Crédito/Autor da imagem</FormLabel>
                <CustomInput
                  value={formValues.creditoAutorImagem.value}
                  onChange={(e) => handleFieldChange('creditoAutorImagem', e.target.value)}
                />
              </FormControl>
            </div>
          </div>

          <Flex mt="35px" justifyContent="end">
            <Button
              m="6px"
              onClick={() => navigate('/cms/configuracao/banner')}
            >
              Desistir
            </Button>
            <Button
              type="submit"
              m="6px"
              color="#FFF"
              bgColor="#1665D8"
              _hover={{ opacity: 0.8 }}
              isDisabled={!isFormValid()}
            >
              Criar Banner
            </Button>
          </Flex>
        </form>
      </Box>

      <Modal show={isErrorModalOpen} onHide={() => setIsErrorModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Atenção!</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setIsErrorModalOpen(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isSuccessModalOpen} onHide={() => {
        setIsSuccessModalOpen(false);
        navigate('/cms/configuracao/banner');
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Sucesso!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Banner criado com sucesso!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => {
            setIsSuccessModalOpen(false);
            navigate('/cms/configuracao/banner');
          }}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isErrorModalOpen} onHide={() => setIsErrorModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Atenção!</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setIsErrorModalOpen(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

    </Flex>
  );
};

export default InserirBanner;
