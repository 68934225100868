import { api } from './';

interface ApiError {
  response?: {
    data: any;
    status: number;
    statusText: string;
  };
}

export const inserirAplicacao = async (params: any) => {
  try {
    const response = await api.post('/aplicativos/inserir', params);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao comunicar com o servidor');
  }
};

export const editarAplicativo = async (id: number, params: any) => {
  try {
    const response = await api.put(`/aplicativos/editar/${id}`, params);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao comunicar com o servidor');
  }
};

export const listarAplicacao = async (page: number, size: number) => {
  try {
    const response = await api.get(`/aplicativos/listar?pagina=${page}&tamanhoPagina=${size}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao comunicar com o servidor');
  }
};