import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Flex,
  Heading,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react'

import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb';
import TabelaAplicativo from './tabela-aplicativo';
import { listarAplicacao } from '../../../api/aplicativo';
import Paginacao from '../../../components/common/paginacao/paginacao';

const GerenciarAplicativo = () => {
  const [aplicativos, setAplicativos] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate()

  useEffect(() => {    
    const fetchAplicativos = async () => {
      try {
        const data = await listarAplicacao(currentPage, itemsPerPage);
        console.log(data);
        setAplicativos(data);
      } 
      catch (error) {
        console.error('Erro ao buscar aplicativos:', error);
      }
    };    

    fetchAplicativos();
    
    // const mockAplicativos = [
    //   { id: 1, nomeAplicativo: 'Aplicativo 1', ativo: true },
    //   { id: 2, nomeAplicativo: 'Aplicativo 2', ativo: false },
    //   { id: 3, nomeAplicativo: 'Aplicativo 3', ativo: true },
    //   { id: 4, nomeAplicativo: 'Aplicativo 4', ativo: false },
    // ];
    
    // setAplicativos(mockAplicativos);
  }, [currentPage, itemsPerPage]);

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Aplicativo
      </Heading>

      {/* Search bar */}
      <Box m="40px 0" w="100%">
        <HStack spacing={5}>
          <Button
            color="#fff"
            bgColor="#1789FC"
            onClick={() => navigate('novo-aplicativo')}
          >
            Novo Aplicativo
          </Button>
        </HStack>
      </Box>

      <Box>
        <TabelaAplicativo 
          listaAplicativo={aplicativos} 
          listaVazia="Nenhum dado disponível." 
        />
      </Box>

      <Paginacao 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={setCurrentPage}
      />
    </Flex>
  )
}

export default GerenciarAplicativo
