import React, { useEffect, useState } from 'react'

import { Heading, Flex, Grid, GridItem, Box } from '@chakra-ui/react'

import withAuth from '../../contexts/AuthContext/withAuth'
import ImageCard, { Props as ImageCardProps } from './components/ImageCard'

function Library() {
  const [imageList, setImageList] = useState([{} as ImageCardProps] as any)

  useEffect(() => {
    setImageList([
      { imgUrl: 'https://picsum.photos/200' },
      { imgUrl: 'https://picsum.photos/200' },
      { imgUrl: 'https://picsum.photos/200' },
      { imgUrl: 'https://picsum.photos/200' }
    ])
  }, [])

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <Heading fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Biblioteca
      </Heading>
      <Box m="40px 0" w="100%">
        <Grid templateColumns="3fr 3fr 3fr 3fr" w="100%" gap={10}>
          {imageList.map((i: ImageCardProps, index: number) => (
            <GridItem key={index}>
              <ImageCard imgUrl={i.imgUrl} />
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Flex>
  )
}

export default withAuth(Library)
