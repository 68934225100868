import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react'

const postItem = [
  { title: 'AAA', content: 'Teste teste teste' },
  { title: 'BBB', content: 'Teste1 test1e teste1' }
]

function PostConfiguration() {
  return (
    <Tabs variant="enclosed" defaultIndex={1}>
      <TabList>
        {postItem.map((item, index) => (
          <Tab
            key={index}
            _selected={{
              bg: 'white',
              border: '1px solid #EAEDF3',
              borderBottom: 'none'
            }}
            color="#3E3F42"
            border="1px solid #EAEDF3"
            borderRadius="4px"
          >
            {item.title}
          </Tab>
        ))}
      </TabList>
      <TabPanels
        border="1px solid #EAEDF3"
        borderRadius="4px"
        bgColor="white"
        height="64vh"
        color="black"
      >
        {postItem.map((item, index) => (
          <TabPanel key={index} bgColor="white">
            <p>{item.content}</p>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

export default PostConfiguration
