import React from 'react';
import ReactLoading from 'react-loading';

const LoadingSpinner = ({ type, color }: any) => (
  <div style={spinnerContainerStyle}>
    <ReactLoading type={type} color={color} height={150} width={70} />
  </div>
);

const spinnerContainerStyle = {
    display: 'flex',
    justifyContent: 'center', 
    marginLeft: '65vh',
    marginTop: '5vh',
  };

export default LoadingSpinner;
