import { RiShareBoxLine } from 'react-icons/ri'

import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Icon,
  IconButton,
  Text
} from '@chakra-ui/react'

function MostVisitPostsTable() {
  return (
    <Table>
      {' '}
      <Thead>
        <Tr color="#9EA0A5">
          <Th color="#9EA0A5">Postagem</Th>
          <Th color="#9EA0A5">Visitantes</Th>
          <Th color="#9EA0A5">Taxa de conversão</Th>
        </Tr>
      </Thead>
      <Tbody></Tbody>
      <Tr>
        <Td
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          pr="0"
        >
          <Text color="#3E3F42">Criação de mais Creches</Text>
          <IconButton bgColor="#fff" aria-label="Dowload">
            <Icon
              fontSize="26px"
              mx="16px"
              color="#9EA0A5"
              as={RiShareBoxLine}
            />
          </IconButton>
        </Td>
        <Td pr="0">
          <Text color="#3E3F42">4.000</Text>
        </Td>
        <Td pr="0">
          <Text color="#3E3F42">88.50%</Text>
        </Td>
      </Tr>
    </Table>
  )
}

export default MostVisitPostsTable
