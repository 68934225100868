import axios, { AxiosError, isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIMask } from 'react-imask';
import { validateUser } from '../../api/forgotPassword';
import { ModalError } from '../../components/Modal/modalError';
import {
  Container,
  Box,
  Img,
  Input,
  Stack,
  FormControl,
  InputGroup,
  FormErrorMessage,
  Button,
  Text,
  Flex,
  Heading
} from '@chakra-ui/react';
import keyInHand from '../../assets/keyInHand.svg';

const ForgotPassword = () => {

  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    cpf: {
      value: '',
      error: false
    },
    email: {
      value: '',
      error: false
    }
  });

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');

  const handleFieldChange = (field: string, value: string) =>
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    });

  const { ref: cpfRef } = useIMask(
    { mask: '000.000.000-00' },
    {
      onAccept: (value) => handleFieldChange('cpf', value)
    }
  );

  const isValidateForm = () => {
    let isValid = true;
    if (!formValues.cpf.value) {
      setFormValues({
        ...formValues,
        cpf: { ...formValues.cpf, error: true }
      });
      isValid = false;
    }

    if (!formValues.email.value) {
      setFormValues({
        ...formValues,
        email: { ...formValues.email, error: true }
      });
      isValid = false;
    }

    return isValid;
  };

  const handleErrorClose = () => setShowErrorModal(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const cpf = formValues.cpf.value.replace(/\.|-/gm, '');
    const email = formValues.email.value;

    try {
      if (isValidateForm()) {
        const res = await validateUser(cpf, email);

        console.log(res);

        if (res.status === 200) {
          console.log('No error');
          navigate('/new-password');
        } else {
          setModalErrorMessage(res.data.message || 'Unknown error');
          setShowErrorModal(true);
        }
      }
    } catch (error) {
      if (isAxiosError(error)) {
        setModalErrorMessage(error.response?.data?.message || 'Unknown error');
      } else {
        setModalErrorMessage('An unknown error occurred');
      }
      setShowErrorModal(true);
    }
  };

  return (
    <Container
      overflowX="auto"
      color="#000"
      bgColor="#EDEDED"
      display="flex"
      alignItems="center"
      justifyContent="center"
      w="100%"
      maxWidth="100%"
      maxHeight="100%"
      height="100vh"
      p="0"
      m="0"
    >
      <Box
        width="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p="0 40px"
        paddingBottom={'30px'}
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={4} w="100%">
            <Flex justifyContent="center" alignItems="center">
              <Img src={keyInHand} width={'20%'} />
            </Flex>
            <Heading fontWeight="600" textAlign={'center'} fontSize="26px" w="100%">
              Recuperação de senha
            </Heading>
            <Text color={'grey'} textAlign={'center'}>
              Antes de recuperar sua senha, preencha os campos abaixo para
              encontrarmos seus dados no sistema
            </Text>
            <Flex>
              <FormControl isInvalid={formValues.cpf.error}>
                <InputGroup>
                  <Input
                    borderColor={'lightgray'}
                    placeholder='CPF'
                    width={'300px'}
                    ml={'5px'}
                    maxLength={14}
                    aria-label="cpf"
                    id="cpf"
                    ref={cpfRef}
                    value={formValues.cpf.value}
                    onChange={(e) => handleFieldChange('cpf', e.target.value.trim())}
                  />
                </InputGroup>
                {formValues.cpf.error && (
                  <FormErrorMessage>Campo Obrigatório</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={formValues.email.error}>
                <InputGroup>
                  <Input
                    borderColor={'lightgray'}
                    placeholder='E-mail'
                    ml={'5px'}
                    width={'300px'}
                    type={'email'}
                    aria-label="email"
                    required
                    value={formValues.email.value}
                    onChange={(e) => handleFieldChange('email', e.target.value)}
                  />
                </InputGroup>
                {formValues.email.error && (
                  <FormErrorMessage>Campo Obrigatório</FormErrorMessage>
                )}
              </FormControl>
            </Flex>
            <Flex
              paddingTop={'20px'}
              justifyContent={'flex-end'}
              alignItems={'flex-end'}
              flexDirection={'row'}
            >
              <Button
                fontWeight="600"
                m='6px'
                onClick={() => {
                  navigate('/login');
                }}
              >
                Desistir
              </Button>
              <Button
                type="submit"
                m="6px"
                color="#FFF"
                bgColor="#1665D8"
                _hover={{ opacity: 0.8 }}
              >
                Recuperar senha
              </Button>
            </Flex>
          </Stack>
        </form>
      </Box>
      <ModalError
        isOpen={showErrorModal}
        onClose={handleErrorClose}
        onConfirm={handleErrorClose}
        message={modalErrorMessage}
      />
    </Container>
  );
};

export default ForgotPassword;
