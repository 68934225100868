import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Flex,
  Heading,
  Box,
  Button,
  Select,
  HStack,
  Input
} from '@chakra-ui/react'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import TabelaUsuario from '../tabela-usuario/tabela-usuario'
import Paginacao from '../../../../components/common/paginacao/paginacao'
import { listarUsuarios } from '../../../../api/usuario'

const mockUsers = [
  { dscPerfilAcesso: 'Administrador', codPerfilAcesso: '1', ativo: 1 },
  { dscPerfilAcesso: 'Usuário Comum', codPerfilAcesso: '2', ativo: 1 },
  { dscPerfilAcesso: 'Gestor', codPerfilAcesso: '3', ativo: 0 },
  { dscPerfilAcesso: 'Moderador', codPerfilAcesso: '4', ativo: 1 },
]

const GerenciarUsuario = () => {
  const [usuarios, setUsuarios] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate()

  useEffect(() => {    
    const fetchUsuarios = async () => {
      try {
        const data = await listarUsuarios(currentPage, itemsPerPage);
        console.log(data);
        setUsuarios(data);
      } 
      catch (error) {
        console.error('Erro ao buscar usuários:', error);
      }
    };    

    fetchUsuarios();
    
    // const mockUsuarios = [
    //   { dscPerfilAcesso: 'Administrador', codPerfilAcesso: '1', ativo: 1 },
    //   { dscPerfilAcesso: 'Usuário Comum', codPerfilAcesso: '2', ativo: 1 },
    //   { dscPerfilAcesso: 'Gestor', codPerfilAcesso: '3', ativo: 0 },
    //   { dscPerfilAcesso: 'Moderador', codPerfilAcesso: '4', ativo: 1 },
    // ];
    // setUsuarios(mockUsuarios);
  }, [currentPage, itemsPerPage]);

  const toggleUserActiveState = (codPerfilAcesso: string, ativo: boolean) => {
    setUsuarios((prevUsuarios: any) =>
      prevUsuarios.map((usuario: any) =>
        usuario.codPerfilAcesso === codPerfilAcesso
          ? { ...usuario, ativo: ativo ? 1 : 0 }
          : usuario
      )
    )
  }

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Gerenciar Perfil de Usuário
      </Heading>

      {/* Search bar */}
      <Box m="40px 0" w="100%">
        <HStack spacing={5}>
          <Button
            color="#fff"
            bgColor="#1789FC"
            onClick={() => navigate('novo-usuario')}
          >
            Novo Perfil de Usuário
          </Button>
          {/* <Flex dir="inline" w="70%">
            <Select defaultValue="cpf" w="20%">
              <option value="cpf">CPF</option>
              <option value="nome">Nome</option>
            </Select>

            <Input ml="12px" placeholder="Digite Aqui" />
          </Flex> */}
        </HStack>
      </Box>

      <Box>
        <TabelaUsuario listaUsuario={usuarios} 
          listaVazia="Nenhum dado disponível." 
        />
      </Box>

      <Paginacao 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={setCurrentPage}
      />
      {/* Table */}
    </Flex>
  )
}

export default GerenciarUsuario;
