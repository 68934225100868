import { Flex, Heading, Button, Grid, GridItem, Input, Box, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import CustomInput from '../../../../components/common/Input/input'
import { inserirAplicacao } from '../../../../api/aplicativo'

function NovoAplicativo() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate()

  const [formValues, setFormValues] = useState({
    dscAplicativo: {
      value: '',
      error: false
    },
    dscToken: {
      value: '',
      error: false
    }
  })

  const isFormValid = () => {
    const { dscAplicativo } = formValues;
    const { dscToken } = formValues;
    return (
      dscAplicativo.value && dscToken.value).trim() && 
      dscAplicativo.value.length >= 5 && dscToken.value.length >= 5
  };

  const handleFieldChange = (field: string, value: string) =>
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    })

    const handleSubmit = async () => {
      const { dscAplicativo } = formValues;
      const { dscToken } = formValues;
  
      const params = {
        dscAplicativo: dscAplicativo.value || null,
        dscToken: dscToken.value || null,
      };
      
      try {
        await inserirAplicacao(params);
        setIsSuccessModalOpen(true);
      } catch (error) {
        setErrorMessage('Erro ao adicionar aplicativo. Tente novamente.');
        setIsErrorModalOpen(true);
      }
    };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        mb="20px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Incluir Novo Aplicativo
      </Heading>
        <div className="row mb-3">
          <div className="col-md-6 mb-4">
          <FormControl isRequired>
          <FormLabel mb="8px">Nome do Aplicativo</FormLabel>
            <CustomInput
              value={formValues.dscAplicativo.value}
              onChange={(e) => handleFieldChange('dscAplicativo', e.target.value)}
              maxLength={60}
            />
          </FormControl>
          </div>
          <div className="col-md-6 mb-4">
          <FormControl isRequired>
          <FormLabel mb="8px">Token do Aplicativo</FormLabel>
            <CustomInput
              value={formValues.dscToken.value}
              onChange={(e) => handleFieldChange('dscToken', e.target.value)}
              maxLength={60}
            />
          </FormControl>
          </div>
        </div>

          <Box alignItems={'flex-end'}>
            <Button type="submit" m="px" onClick={() => navigate(-1)}>
              Desistir
            </Button>
            <Button
              type="submit"
              m="6px"
              color="#FFF"
              bgColor="#1665D8"
              _hover={{ opacity: 0.8 }}
              onClick={() => {
                handleSubmit();
                // navigate(-1);
              }}
              isDisabled={!isFormValid()}
            >
              Adicionar Aplicativo
            </Button>
          </Box>

      <Modal isOpen={isSuccessModalOpen} onClose={() => {
        setIsSuccessModalOpen(false);
        navigate(-1);
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sucesso!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Aplicativo adicionado com sucesso!</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => {
              setIsSuccessModalOpen(false);
              navigate(-1);
            }}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atenção!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{errorMessage}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => setIsErrorModalOpen(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default NovoAplicativo;
