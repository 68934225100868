import {
  Box,
  Button,
  Checkbox,
  SimpleGrid,
  GridItem,
  Heading,
  Flex,
  Icon,
  useColorMode
} from '@chakra-ui/react'

import VisualizeContent from '../../components/VisualizeContent'
import { useEffect, useState } from 'react'
import WhatToPublish from '../WhatToPublish'
import { api } from '../../../../api'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faTelegram, faTumblr, faTiktok, faLinkedin, faInstagram, faYoutube, faWhatsapp, faFacebookSquare} from '@fortawesome/free-brands-svg-icons';

export default function WhereToPublish() {
  interface DataItem {
    seq_pagina: any;
    nom_pagina: string;
    sit_pagina: any; 
    sit_registro: number;
  }
  
  interface PageData {
    pageName: string;
    situation: any;
    register: any;
    data: PageData[];
    seq_pagina: number;
  }

  const [ShowNews, setShowNews] = useState(false);
  const [ShowPublishContent, setShowPublishContent] = useState(true);
  const [isBannerActive, setIsBannerActive] = useState(true);
  const { pageName } = useParams();
  const { colorMode } = useColorMode();
  
  const [modalEditor, setModalEditor] = useState({
    text: false,
    image: false,
    video: false
  })

  const checkboxList = [
    {
      label: 'Facebook',
      onClick: () => {},
      icon: <FontAwesomeIcon icon={faFacebookSquare} fontSize={18} color={"#1789fc"}/> 
    },
    {
      label: 'YouTube',
      onClick: () => {},
      icon: <FontAwesomeIcon icon={faYoutube} fontSize={18} color={"#ff0000"} />
    },
    {
      label: 'Whatsapp',
      onClick: () => {},
      icon: <FontAwesomeIcon icon={faWhatsapp} fontSize={18} color={"#43e460"} />
    },
    {
      label: 'TikTok',
      onClick: () => {},
      icon: <FontAwesomeIcon icon={faTiktok} fontSize={18} color={'#080404'} />
    },
    {
      label: 'Instagram',
      onClick: () => {},
      icon: <FontAwesomeIcon icon={faInstagram} fontSize={18} color={"#ec1393"}/> 
    },
    {
      label: 'Telegram',
      onClick: () => {},
      icon: <FontAwesomeIcon icon={faTelegram} fontSize={18} color={'#249eda'}/>
    },
    {
      label: 'Linkedin',
      onClick: () => {},
      icon: <FontAwesomeIcon icon={faLinkedin} fontSize={18} color={"#0474c0"} />
    },
    {
      label: 'Tumblr',
      onClick: () => {},
      icon: <FontAwesomeIcon icon={faTumblr} fontSize={18} color={'#385474'} />
    },
    {
      label: 'Twitter',
      onClick: () => {},
      icon: <FontAwesomeIcon icon={faXTwitter} fontSize={18} color={"#080404"} />
    },
  ];
  
  const [buttonsList, setButtonsList] = useState([
    {
      label: 'Mídias Sociais',
      onClick: () => {}
    },
    {
      label: 'Banner',
      onClick: () => {
        setIsBannerActive(!isBannerActive);
      }
    }    
  ])    

  useEffect(() => {
    if (isBannerActive) {
      setShowNews(false); 
      setShowPublishContent(true);
    } else {
      setShowNews(true);
      setShowPublishContent(false); 
    }
  }, [isBannerActive]);
 
  const handleInsertModalOpen = (type: string) => {
    console.log(type)
    setModalEditor({ ...modalEditor, [type]: true })
  }

  useEffect(() => {
    const getContent = '/api/cms/content'
    api.get(getContent)
      .then(response => {
        const { data } = response;
        //console.log("dados da tabela: ", data)
  
        const pageDataArray: PageData[] = data.map((item: DataItem) => ({
          id: item.seq_pagina,
          pageName: item.nom_pagina,
          situation: Number(item.sit_pagina), 
          register: Number(item.sit_registro),
        }));
  
        pageDataArray.sort((b, a) => a.seq_pagina - b.seq_pagina);
  
        const filteredPages = pageDataArray.filter((pageData) => pageData.register === 0);

        const additionalButtons = filteredPages.map((pageData) => ({
          label: pageData.pageName,
          onClick: () => {} 
        }));
  
        setButtonsList([...buttonsList, ...additionalButtons]);
        localStorage.setItem('buttonsList', JSON.stringify([...buttonsList, ...additionalButtons]));
      })
      .catch(error => {
        console.error('Erro ao obter os dados do back-end:', error);
      });
  }, [pageName]);

  return (
    <GridItem>
      <Box w="70%">
        <SimpleGrid
          w="100%"
          gridTemplateColumns="repeat(4, 155px)"
          gap="100"
          rowGap="15px"
          columnGap='30px'
          mb="35px"
        >
          {buttonsList.map((btn, index) => (
            <GridItem key={index}>
              <Button
                bg={'#edf2f7'}
                border="1px solid #D8DCE6"
                boxShadow="0px 1px 1px #161D250D"
                fontSize="12px"
                color="#3E3F42"
                minW="100%"
                onClick={() => {
                  if (btn.label === 'Banner') {
                    setIsBannerActive(!isBannerActive);
                  } else {
                    btn.onClick();
                  }
                }}
                bgColor={btn.label === 'Banner' ? (isBannerActive ? '#edf2f7' : 'gray.300') : ''}
                style={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word',
                  lineHeight: '1.4',
                  padding: btn.label.split('\n').length > 3 ? '30px' : '0',
                }}
              >
                {btn.label.length > 5 ? (
                  btn.label
                ) : (
                  btn.label.replace(/(.{15})/g, "$1\n")
                )}
              </Button>
            </GridItem>
          ))}
        </SimpleGrid>
        <Heading
          fontWeight="600"
          fontSize="21px"
          color="#6F6F6F"
          w="100%"
          mb="24px"
        >
          Selecione onde deseja publicar o conteúdo
        </Heading>
        <SimpleGrid gridTemplateColumns="repeat(4, 130px)" mb="50px">
          {checkboxList.map((btn, index) => (
            <GridItem key={index}>
              <Checkbox color="#9EA0A5" fontSize="13px" onClick={btn.onClick} mb={2}>
                <Flex alignItems="center">
                  {btn.icon}
                  <span style={{ marginLeft: '6px' }}>{btn.label}</span>
                </Flex>
              </Checkbox>
            </GridItem>
          ))}
        </SimpleGrid>
      </Box>
      {ShowNews ? (
      <WhatToPublish
        onInsertTextClick={() => handleInsertModalOpen('text')}
        onInsertVideoClick={() => handleInsertModalOpen('video')}
        onInsertImageClick={() => handleInsertModalOpen('image')}
      />
    ) : (
      ShowPublishContent && <VisualizeContent />
    )}
    </GridItem>
  )
}

export { WhereToPublish };
