import { BsArrowUpRightSquare, BsArrowDownLeftSquare } from 'react-icons/bs'

import { Flex, Text, Icon, Stack } from '@chakra-ui/react'

export interface Props {
  title: string
  value: string
  percentage: string
  isGrowing?: boolean
}

function CardInfoDetail({ title, value, percentage, isGrowing }: Props) {
  return (
    <Stack
      border="1px solid #EAEDF3"
      borderRadius="4px"
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="25px 30px"
      w="100%"
      backgroundColor="#fff"
    >
      <Text fontSize="12px" color="#9EA0A5">
        {title}
      </Text>
      <Text fontSize="52px" color="#3E3F42">
        {value}
      </Text>
      <Flex
        dir="row"
        alignItems="center"
        fontWeight={600}
        color={isGrowing ? '#3BAD4B' : '#EB6A54'}
      >
        {isGrowing ? '+' : '-'}
        <Text marginLeft="4px">{percentage}</Text>
        <Icon
          marginLeft="12px"
          as={isGrowing ? BsArrowUpRightSquare : BsArrowDownLeftSquare}
          mr="10px"
        />
      </Flex>
    </Stack>
  )
}

export default CardInfoDetail
