import { BiCheckSquare, BiTrash } from 'react-icons/bi'

import { Box, Image, Square, Button, Flex, Icon } from '@chakra-ui/react'

export interface Props {
  imgUrl: string
}

function ImageCard({ imgUrl }: Props) {
  return (
    <Box
      w="100%"
      p="9px"
      border="1px solid #EAEDF3"
      bgColor="#fff"
      borderRadius="4px"
    >
      <Square>
        <Image w="100%" m="0" p="0" borderRadius="4px" src={imgUrl} />
      </Square>
      <Flex alignItems="flex-start" flexDirection="column" p="20px">
        <Button variant="link" color="#3E3F42" m="10px 0">
          <Icon as={BiCheckSquare} mr="10px" />
          Usar Imagem
        </Button>
        <Button variant="link" color="#FF0000" m="10px 0">
          <Icon as={BiTrash} mr="10px" />
          Deletar Imagem
        </Button>
      </Flex>
    </Box>
  )
}

export default ImageCard
