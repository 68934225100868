import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function withAuth(Component: React.FC) {
  return function AuthenticatedComponent(props: any) {
    const token = localStorage.getItem('token')
    const navigate = useNavigate()

    useEffect(() => {
      if (!token) {
        navigate('/login')
      }
    }, [token, navigate])

    return token ? <Component {...props} /> : null
  }
}

export default withAuth
