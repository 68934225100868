import { Box, Text } from '@chakra-ui/react'

import MostVisitPostsTable from '../MostVisitPostsTable'

function MostVisitPost() {
  return (
    <Box borderRadius="4px" border="1px solid #EAEDF3" bgColor="#fff">
      <Box padding="20px 25px" borderBottom=" 1px solid #EAEDF3">
        <Text color="#3E3F42" fontSize="16px" fontWeight="600">
          Posts Mais Visitados
        </Text>
      </Box>
      <MostVisitPostsTable />
    </Box>
  )
}

export default MostVisitPost
