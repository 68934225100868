import { Textarea, TextareaProps } from '@chakra-ui/react';

const CustomTextArea: React.FC<TextareaProps> = (props) => {
  return (
    <Textarea
      variant="outline"
      maxLength={250}
      backgroundColor={'#fff'}
      borderColor="gray.300"
      _hover={{
        borderColor: 'blue.500',
      }}
      _focus={{
        borderColor: 'blue.500',
        boxShadow: '0 0 0 1px blue.500',
      }}
      {...props}
    />
  );
};

export default CustomTextArea;
