import {
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  ModalBody,
  ModalOverlay
} from '@chakra-ui/react'

interface ModalPopUpProps {
  isOpen: boolean
  children: React.ReactNode
  onClose: () => void
  header?: React.ReactNode
  footer?: React.ReactNode
}

export const ModalPopUp = ({
  footer,
  children,
  isOpen,
  onClose
}: ModalPopUpProps) => {
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent
        marginTop={'200px'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        p="12px 16px"
      >
        <ModalBody display="flex" flexDirection="column" alignItems="center">
          {children}
          <Flex justifyContent="end">
            <Button
              backgroundColor={'#8ec2ed'}
              type="submit"
              m="6px"
              mt={'15px'}
              onClick={onClose}
            >
              OK
            </Button>
          </Flex>
        </ModalBody>
        {footer ? <ModalFooter>{footer}</ModalFooter> : null}
      </ModalContent>
    </Modal>
  )
}
