import { FaUsers, FaBookOpen, FaRegEnvelope, FaTags, FaShareAlt, FaBars } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Grid, GridItem } from '@chakra-ui/react'

import { SettingsCard } from '../../../../components/SettingsCard'
import './grid-configuracao.scss'

const GridSettings = () => {
  const navigate = useNavigate()

  return (
    <div className='main-container'>
      <GridItem>
        <SettingsCard
          buttonText="Acessar"
          onClick={() => navigate('gerenciar-usuario')}
          icon={FaUsers}
          cardTitle="Gerenciar Perfil de Usuário"
        />
      </GridItem>
      <GridItem>
        <SettingsCard
          buttonText="Acessar"
          onClick={() => navigate('gerenciar-paginas')}
          icon={FaBookOpen}
          cardTitle="Gerenciar Páginas"
        />
      </GridItem>
      <GridItem>
        <SettingsCard
          buttonText="Acessar"
          onClick={() => navigate('servico-comunicacao')}
          icon={FaRegEnvelope}
          cardTitle={"Serviço de Comunicação"}
        />
      </GridItem>
      <GridItem>
        <SettingsCard
          buttonText="Acessar"
          onClick={() => navigate('gerenciar-categoria')}
          icon={FaTags}
          cardTitle={"Gerenciar Categorias"}
        />
      </GridItem>
      <GridItem>
        <SettingsCard
          buttonText="Acessar"
          onClick={() => navigate('aplicativo')}
          icon={FaShareAlt}
          cardTitle={"Aplicativo"}
        />
      </GridItem>
      <GridItem>
        <SettingsCard
          buttonText="Acessar"
          onClick={() => navigate('gerenciar-menu')}
          icon={FaBars}
          cardTitle={"Gerenciar Menu"}
        />
      </GridItem>
    </div>
  )
}

export default GridSettings
