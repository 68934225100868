import { Heading, Grid, Button, ButtonProps, Text } from '@chakra-ui/react'

import { Video } from '../../../../components/Icons'
import Library from '../../../../components/Icons/Library'
import { Text as IconText } from '../../../../components/Icons/Text'
import { useNavigate } from 'react-router-dom';


const ContentButtons = (props: ButtonProps) => (
  <Button
    display="flex"
    flexDirection="column"
    border="1px solid #E2E5ED"
    borderRadius="4px"
    bgColor="#fff"
    w="100%"
    h="95px"
    {...props}
  />
)

export interface WhatToPublishProps {
  onInsertTextClick: () => void;
  onInsertImageClick: () => void
  onInsertVideoClick: () => void
}

function WhatToPublish({
  onInsertImageClick,
  onInsertTextClick,
  onInsertVideoClick
}: WhatToPublishProps) {
  const navigate = useNavigate();
  return (
    <>
      {' '}
      <Grid
        gridTemplateColumns="repeat(3, 200px)"
        width="70%"
        justifyContent="space-between"
        mb="56px"
        gap={50}
      >
        {/* <ContentButtons onClick={() => navigate('/cms/content/createText')}>
          <IconText fontSize="48px" />
          <Text fontSize="12px" color="#3E3F42">
            Criar texto
          </Text>
        </ContentButtons>{' '}
        <ContentButtons onClick={onInsertImageClick}> */}
        <ContentButtons onClick={() => navigate('/cms/content/banner')}>
          <Library stroke="#1789FC" fontSize="48px" />
          <Text fontSize="12px" color="#3E3F42">
            Inserir Imagem
          </Text>
        </ContentButtons>
        {/* <ContentButtons onClick={() => navigate('/cms/content/createVideo')}>
          <Video fontSize="48px" />
          <Text fontSize="12px" color="#3E3F42">
            Inserir vídeo
          </Text>
        </ContentButtons> */}
      </Grid>{' '}
    </>
  )
}

export default WhatToPublish
