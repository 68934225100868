import { FaNewspaper, FaImage } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Grid, GridItem } from '@chakra-ui/react'

import { SettingsCard } from '../../../components/SettingsCard'
import './gridPublicacao.scss'

const GridPublicacao = () => {
  const navigate = useNavigate()

  return (
    <div className='main-container'>
      <GridItem>
        <SettingsCard
          buttonText="Acessar"
          onClick={() => navigate('noticia')}
          icon={FaNewspaper}
          cardTitle="Notícias"
        />
      </GridItem>
      <GridItem>
        <SettingsCard
          buttonText="Acessar"
          onClick={() => navigate('banner')}
          icon={FaImage}
          cardTitle={"Banner"}
        />
      </GridItem>
    </div>
  )
}

export default GridPublicacao
