import React, { useEffect, useState } from 'react'

// import ModalDeleteNews from './modalDeleteNews'
import { AiFillEdit, AiOutlineDelete } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

import { useDisclosure } from '@chakra-ui/react'
import {
  Container,
  Box,
  Flex,
  Checkbox,
  Text,
  Stack,
  Icon
} from '@chakra-ui/react'

import { api } from '../../../../api'
import ScheduleOrpublish from '../VisualizeContent/publicContent/scheduleOrpublish'
import LoadingSpinner from './loading'
import { ModalConfirm } from './modalConfirm'
import { ModalDeleteNews } from './modalDeleteNews'

function PreviewCard({ previewMode, previewImageSrc }: any) {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [bannerChecked, setBannerChecked] = useState('')
  const [bannerList, setBannerList] = useState<Banner[]>([])
  const [isLoading, setIsLoading] = useState(true)

  interface Banner {
    seq_conteÚdo: number
    nom_publicacao: string
    bin_publicacao: any
    dsc_publicacao: string
  }

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await api.get('/api/cms/content/banner/:id')
        setBannerList(response.data)
        setIsLoading(false)
      } catch (error) {
        console.error('Erro ao buscar banners:', error)
        setIsLoading(false)
      }
    }

    fetchBanners()
  }, [])

  if (isLoading) {
    return <LoadingSpinner type="spin" color="#007bff" />
  }

  console.log('banners :', bannerList)

  return (
    <>
      {bannerList.map((banner: Banner, index: any) => {
        //.slice(0, bannersPerPage)
        return (
          <Container
            key={index.toString()}
            m="0"
            p="8px"
            width="105%"
            height="auto" // Alterado para altura automática
            h="100%"
            bgColor="#fff"
            borderRadius="4px"
            border="1px solid #EAEDF3"
            boxShadow="0px 1px 3px #0000000A"
            mb="10px" // Adicionado espaçamento entre banners
          >
            <Text
              textAlign={'center'}
              alignItems={'center'}
              fontWeight="600"
              fontSize="15px"
              color="#6F6F6F"
              w="100%"
              h={'9%'}
              mb="5px"
            >
              {banner.nom_publicacao.toLocaleUpperCase()}
            </Text>
            <img
              id={`${banner.seq_conteÚdo}`}
              src={`${banner.bin_publicacao}`}
              alt={banner.dsc_publicacao.toLowerCase()}
              style={{ width: '100%', height: '60%', objectFit: 'fill' }} //contain
            />
            <Box h="45px" overflow="auto" mb="4px" mt="1px">
              <Text fontSize="0.8rem" color="#6F6F6F">
                {banner.dsc_publicacao}
              </Text>
            </Box>
            <Box h="20%" borderTop="1px solid #EAEDF3">
              <Flex
                flexDir="row"
                justifyContent="space-around"
                alignItems="center"
                h="100%"
              >
                <Box
                  w="44px"
                  h="45px"
                  borderRadius="27.5"
                  bgColor="#e6e6e6"
                  ml={3}
                ></Box>
                <Stack color="#9EA0A5">
                  <Box display="flex" flexDir="row" alignItems="center">
                    <Icon
                      as={AiFillEdit}
                      fontSize={17}
                      color="#1789fc"
                      mr={2}
                    />
                    <button
                      onClick={() =>
                        navigate(
                          `/cms/content/bannerEdit/${banner.seq_conteÚdo}`,
                          {
                            state: {
                              title: banner.nom_publicacao,
                              image: banner.bin_publicacao,
                              description: banner.dsc_publicacao
                            }
                          }
                        )
                      }
                    >
                      <Text fontSize={['60%', '80%']}>Editar Banner</Text>
                    </button>
                  </Box>
                  <Box display="flex" flexDir="row" alignItems="center">
                    <Checkbox
                      mr="10px"
                      borderColor="#1789fc"
                      value={bannerChecked}
                    >
                      <Text fontSize={['60%', '80%']}>Usar Banner</Text>
                    </Checkbox>{' '}
                  </Box>
                  <Box display="flex" flexDir="row" alignItems="center">
                    <Icon
                      as={AiOutlineDelete}
                      fontSize={17}
                      color="#1789fc"
                      mr={2}
                    />
                    <button onClick={onOpen}>
                      <Text fontSize={['60%', '80%']}>Deletar Banner</Text>
                      <ModalDeleteNews
                        isOpen={isOpen}
                        onClose={onClose}
                        // eslint-disable-next-line react/no-children-prop
                        children={undefined} // onOpen={onOpen}
                        // banner={banner}
                      />
                    </button>
                  </Box>
                </Stack>
              </Flex>
            </Box>
          </Container>
        )
      })}
    </>
  )
}

export default PreviewCard
