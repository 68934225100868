import { useEffect, useState } from 'react'

import { Container, Text, Grid, GridItem } from '@chakra-ui/react'

import { getProfiles } from '../../api/profile'
import withAuth from '../../contexts/AuthContext/withAuth'
import { IProfile } from '../../utils/interface/Profile'
import Card, { Props as CardProps } from './components/Card'
import Filter from './components/Filter'

const cards: CardProps[] = [
  {
    name: 'Maria',
    ocupation: 'Arquiteta',
    posts: 15,
    actions: 10,
    urlAvatar: 'https://picsum.photos/id/237/200/300',
    imgsProfile: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300'
    ]
  },
  {
    name: 'Pedro',
    ocupation: 'Desenvolvedor',
    posts: 15,
    actions: 10,
    urlAvatar: 'https://picsum.photos/200/300',
    imgsProfile: [
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300',
      'https://picsum.photos/200/300'
    ]
  }
]

function Profile() {
  const [profiles, setProfiles] = useState<IProfile[]>([])

  useEffect(() => {
    getProfilesList()
  }, [])

  const getProfilesList = async () => {
    const { data } = (await getProfiles()) as { data: IProfile[] }
    setProfiles(data)
  }

  return (
    <Container
      p="16px"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width="100%"
      maxW="100%"
      backgroundColor="#FBFBFD"
    >
      <Text
        width="100%"
        textAlign="start"
        margin="10px 27px"
        color="#6F6F6F"
        fontWeight="600"
        fontSize="21px"
      >
        Painel de Perfis
      </Text>
      <Grid
        w="100%"
        templateColumns="repeat(3, 1fr)"
        gap="27px"
        margin="0 27px"
      >
        <GridItem colSpan={2}>
          <Grid templateColumns="repeat(2, 1fr)" gap="27px">
            {profiles.map((card, index) => (
              <GridItem key={index} colSpan={1}>
                <Card
                  name={card.name}
                  ocupation={card.ocupation}
                  posts={card.posts}
                  actions={card.actions}
                  urlAvatar={card.urlAvatar}
                  imgsProfile={card.imgsProfile}
                />
              </GridItem>
            ))}
          </Grid>
        </GridItem>

        <GridItem colSpan={1}>
          <Filter />
        </GridItem>
      </Grid>
    </Container>
  )
}

export default withAuth(Profile) 
