import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Flex,
  Heading,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react'

import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb';
import TabelaCategoria from './TabelaCategoria/tabela-categoria';
import { listarCategorias } from '../../../api/categoria';
import Paginacao from '../../../components/common/paginacao/paginacao';

const GerenciarCategoria = () => {
  const [categorias, setCategorias] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate()

  useEffect(() => {    
    const fetchCategorias = async () => {
      try {
        const data = await listarCategorias(currentPage, itemsPerPage);
        console.log(data);
        setCategorias(data);
      } 
      catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };    

    fetchCategorias();
    
    // const mockCategorias = [
    //   { seqCategoria: 1, dscCategoria: 'Categoria 1', datRegistro: '2023-01-01', numRegistro: 1 },
    //   { seqCategoria: 2, dscCategoria: 'Categoria 2', datRegistro: '2023-02-01', numRegistro: 0 },
    //   { seqCategoria: 3, dscCategoria: 'Categoria 3', datRegistro: '2023-03-01', numRegistro: 1 },
    //   { seqCategoria: 4, dscCategoria: 'Categoria 4', datRegistro: '2023-04-01', numRegistro: 0 },
    // ];
    // setCategorias(mockCategorias);
  }, [currentPage, itemsPerPage]);


  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Gerenciar Categorias
      </Heading>

      {/* Search bar */}
      <Box m="40px 0" w="100%">
        <HStack spacing={5}>
          <Button
            color="#fff"
            bgColor="#1789FC"
            onClick={() => navigate('criar-categoria')}
          >
            Criar Categoria
          </Button>
        </HStack>
      </Box>

      <Box>
        <TabelaCategoria 
          listaCategoria={categorias} 
          listaVazia="Nenhum dado disponível." 
          />
      </Box>

      <Paginacao 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={setCurrentPage}
      />
    </Flex>
  )
}

export default GerenciarCategoria
