(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("@ckeditor/ckeditor5-watchdog"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "@ckeditor/ckeditor5-watchdog"], factory);
	else if(typeof exports === 'object')
		exports["CKEditor"] = factory(require("react"), require("prop-types"), require("@ckeditor/ckeditor5-watchdog"));
	else
		root["CKEditor"] = factory(root["react"], root["prop-types"], root["@ckeditor/ckeditor5-watchdog"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__949__, __WEBPACK_EXTERNAL_MODULE__546__) => {
return 