import { Flex, Box, Img, Text, Divider } from '@chakra-ui/react';
import logo from '../../assets/logo.svg';
import logoPref from '../../assets/logoPref.svg';
import MenuProfile from './components/menuProfile';
import { ModalLogout } from '../Sidebar/modal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

function Header() {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  
  const handleLogoutConfirm = () => {
    logout();
    navigate('/login')
    setModalOpen(false);
  };

  return (
    <Flex
    bgColor="#fff"
    px="30px"
    height="100%"
    maxHeight="76px"
    justifyContent="space-between"
    alignItems="center"
    borderBottom="1px solid #EAEDF3"
    boxShadow="0 1px 5px rgb(152 152 152 / 10%)"
  >
      <Flex flexDir="row" alignItems="center">
        <Box>
          <Img src={logo} />
        </Box>
        <Divider orientation="vertical" mx="10px" borderColor="#D5D5D5" height="30px" />
        <Box>
        <Img ml={'30px'} src={logoPref} />
        </Box>
      </Flex>
      {/* <Box>
        <Img ml={'30px'} src={logoPref} />
      </Box> */}
      <Box>
        <MenuProfile onLogout={handleModalOpen} />
      </Box>
      <ModalLogout
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleLogoutConfirm}
        confirmText="Sim, desejo sair"
        cancelText="Não, desejo permanecer"
        title="Deseja sair da aplicação?"
      >
      </ModalLogout>
    </Flex>
  );
}

export default Header;
