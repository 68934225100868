// src/components/Banner/Banner.tsx

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Heading,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react';
import SettingsBreadcrumb from '../../components/SettingsBreadcrumb';
import TabelaBanner from './tabelaBanner/tabela-banner';
import Paginacao from '../../components/common/paginacao/paginacao';
import * as api from '../../api/banner';

const Banner = () => {
  const [banner, setBanner] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tamanhoPagina, setTamanhoPagina] = useState(10);
  const [codTipoPublicacao, setcodTipoPublicacao] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        console.log('codTipoPublicacao', codTipoPublicacao)
        const data = await api.listarBanner(codTipoPublicacao, currentPage, tamanhoPagina);
        setBanner(data.banners);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Erro ao buscar banners:', error);
      }
    };

    fetchBanner();

    // const mockBanner = [
    //   { seqBanner: 1, dscBanner: 'Aplicativo 1', datRegistro: Date.now(), dataPublicacao: Date.now(), dataFimPublicacao: Date.now() },
    //   { seqBanner: 2, dscBanner: 'AplicdatRegistro 2', datRegistro: Date.now(), dataPublicacao: Date.now(), dataFimPublicacao: Date.now() },
    //   { seqBanner: 3, dscBanner: 'AplicdatRegistro 3', datRegistro: Date.now(), dataPublicacao: Date.now(), dataFimPublicacao: Date.now() },
    //   { seqBanner: 4, dscBanner: 'AplicdatRegistro 4', datRegistro: Date.now(), dataPublicacao: Date.now(), dataFimPublicacao: Date.now() },
    // ];
    
    // setBanner(mockBanner);
  }, [currentPage, tamanhoPagina]);

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Banner
      </Heading>

      <Box m="40px 0" w="100%">
        <HStack spacing={5}>
          <Button
            color="#fff"
            bgColor="#1789FC"
            onClick={() => navigate('novo-banner')}
          >
            Novo Banner
          </Button>
        </HStack>
      </Box>

      <Box>
        <TabelaBanner 
          listaBanner={banner} 
          listaVazia="Nenhum dado disponível." 
        />
      </Box>

      <Paginacao 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={setCurrentPage} 
      />
    </Flex>
  );
};

export default Banner;
