import React from 'react'

import {
  Box,
  Heading,
  Text,
  RadioGroup,
  Stack,
  Radio,
  Container,
  Checkbox,
  Flex,
  Input,
  Button,
  FormControl,
  FormLabel
} from '@chakra-ui/react'

const Filter = () => {
  return (
    <Box
      borderRadius="4px 4px 4px 4px"
      w="95%"
      bgColor="#252529"
      h="500px"
      p="28px"
      color="#FFFFFF"
      fontWeight="600"
    >
      <Heading fontSize="14px" marginBottom="20px">
        Resultado da Filtragem
      </Heading>

      <Container p="0" mb="24px">
        <Text color="#9EA0A5" fontSize="10px" marginBottom="14px">
          Ordenar por
        </Text>
        <RadioGroup>
          <Stack spacing={4} direction="row">
            <Radio value="1">
              <Text fontSize="12px">Relacionado ao se perfil</Text>
            </Radio>
            <Radio value="2">
              <Text fontSize="12px">Nome</Text>
            </Radio>
          </Stack>
        </RadioGroup>
      </Container>

      <Stack direction="column" spacing="2" mb="24px">
        <Text color="#9EA0A5" fontSize="10px">
          ONLY
        </Text>
        <Checkbox defaultChecked>
          <Text fontSize="12px">Em suas postagens</Text>
        </Checkbox>
        <Checkbox>
          <Text fontSize="12px">Postagens antigas</Text>
        </Checkbox>
        <Checkbox>
          <Text fontSize="12px">Interações com outros usuários</Text>
        </Checkbox>
      </Stack>

      <Flex fontWeight="600" flexDirection="column" color="#9EA0A5">
        <FormControl>
          <FormLabel htmlFor="location" fontSize="12px" fontWeight="600">
            Localização
          </FormLabel>
          <Input
            id="location"
            bgColor="#FFF"
            placeholder="Indicar a localização"
            mb="20px"
            height="33px"
            fontSize="12px"
            fontWeight="500"
          />
          <FormLabel htmlFor="health" fontSize="12px" fontWeight="600">
            Área de atuação
          </FormLabel>
          <Input
            id="health"
            bgColor="#FFF"
            placeholder="Saúde"
            mb="20px"
            height="33px"
            fontSize="12px"
            fontWeight="500"
          />
          <Button
            color="#FFF"
            bgColor="#1665D8"
            fontSize="12px"
            fontWeight="700"
            w="100%"
          >
            Resultado da busca
          </Button>{' '}
        </FormControl>
      </Flex>
    </Box>
  )
}

export default Filter
