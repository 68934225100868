import { api } from './';

interface ApiError {
  response?: {
    data: any;
    status: number;
    statusText: string;
  };
}

export const inserirPublicacao = async (params: any) => {
  try {
    const response = await api.post('/conteudo/inserirPublicacao', params);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao comunicar com o servidor');
  }
};

export const editarPublicacao = async (id: any, params: any) => {
  try {
    const response = await api.put(`/conteudo/editar/${id}`, params);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao comunicar com o servidor');
  }
};

export const listarNoticias = async (codTipoPublicacao: number, pagina: number, tamanhoPagina: number) => {
  try {
    const response = await api.get(`/conteudo/listar?codTipoPublicacao=${codTipoPublicacao}&pagina=${pagina}&tamanhoPagina=${tamanhoPagina}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao comunicar com o servidor');
  }
};

export const obterNoticia = async (id: any) => {
  try {
    const response = await api.get(`/conteudo/obter/${id}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao obter notícia');
  }
};

export const listarCategoriasAtivas = async () => {
  try {
    const response = await api.get('/categoria/ativas');
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao comunicar com o servidor');
  }
};

export const deletarRelacionamentoPagina = async (seqPagina: number, seqConteudo: number) => {
  try {
    const response = await api.delete(`/conteudo/deletarRelacionamento?seqConteudo=${seqConteudo}&seqPagina=${seqPagina}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao deletar relacionamento de página');
  }
};