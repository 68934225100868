import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import './styles/styles.scss';
import AuthProvider from './contexts/AuthContext';
import Pages from './pages';
import theme from './utils/theme';

export default function App() {
  return (
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <div style={{ minHeight: '100vh', margin: 0, padding: 0 }}>
          <Pages />
        </div>
      </ChakraProvider>
    </AuthProvider>
  );
}
