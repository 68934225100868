import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { newPassword } from '../../api/forgotPassword'
import { getTokenTemporal } from '../../api/tokenTemporal'

import {
  Container,
  Box,
  Input,
  Stack,
  FormControl,
  InputGroup,
  Text,
  Flex,
  Heading,
  Grid,
  Button,
} from '@chakra-ui/react'
import { ModalError } from '../../components/Modal/modalError'
import { ModalSuccess } from '../../components/Modal/modalSuccess'

interface Usuario {
  nom_usuario: string;
  dsc_cpf: string;
  dsc_email: string;
  dsc_num_celular: string;
}

interface Data {
  seq_usuario: number | null;
  dsc_token: string | null;
  usuario: Usuario;
}

const ChangePassword = () => {
  const navigate = useNavigate()

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [data, setData] = useState<Data | null>(null);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState('');

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalSuccessMessage, setModalSuccessMessage] = useState('');

  const hasEightCharacters = password.length >= 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSpecialChar = /[@#$&+=-]/.test(password);

  const isPasswordValid = hasEightCharacters && hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar;
  const isConfirmPasswordValid = confirmPassword === password;

  useEffect(() => {
    const tokenTemporal = location.href.substring(location.href.lastIndexOf('/') + 1);

    getTokenTemporal(tokenTemporal)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Erro ao buscar token temporal:', error);
        setModalErrorMessage('Sessão deste Token expirou. Tente recuperar a senha novamente.');
        setShowErrorModal(true);
      });
  }, []);

  const handleErrorClose = () => setShowErrorModal(false);
  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    navigate('/login');
  };

  return (
    <>
      <Flex flexDirection={'column'} alignItems={'center'}>
        <Heading mt={'30px'}>
          Alterar Senha
        </Heading>
        <Text color={'grey'} mt={'15px'}>
          Confirme seus dados abaixo e defina sua nova senha
        </Text>
      </Flex>

      <Container
        overflow={'hidden'}
        gap={'5%'}
        overflowX="auto"
        color="#000"
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="100%"
        maxWidth="100%"
        maxHeight="100%"
        height="100vh"
        p="0"
        m="0"
        mt='-50px'
      >
        <Box
          width="30%"
          height={'60%'}
          bgColor="#d8ecfc"
          borderRadius="4px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p="0 40px"
          paddingBottom={'30px'}
        >
          <form>
            <Stack spacing={4} w="100%">
              <Grid textAlign={'initial'} justifyItems={'start'}>
                <Text mt={'20px'} color={'black'} textAlign={'center'} fontWeight={'bold'}>
                  Nome:
                </Text>
                {data && data.usuario.nom_usuario}
                <Text mt={'20px'} color={'black'} textAlign={'center'} fontWeight={'bold'}>
                  CPF:
                </Text>
                {data && data.usuario.dsc_cpf}
                <Text mt={'20px'} color={'black'} textAlign={'center'} fontWeight={'bold'}>
                  E-mail:
                </Text>
                {data && data.usuario.dsc_email}
                <Text mt={'20px'} color={'black'} textAlign={'center'} fontWeight={'bold'}>
                  Celular:
                </Text>
                {data && data.usuario.dsc_num_celular}
              </Grid>
            </Stack>
          </form>
        </Box>
        <Box ml={'20px'}>
          <Box gap={'20px'}>
            <FormControl isInvalid={!isPasswordValid}>
              <InputGroup>
                <label htmlFor="password">
                  Nova Senha
                  <Input
                    w={'110%'}
                    borderColor={'grey'}
                    type={'password'}
                    maxLength={14}
                    aria-label="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value.trim())}
                  />
                </label>
              </InputGroup>
            </FormControl>

            <FormControl isInvalid={!isConfirmPasswordValid}>
              <InputGroup>
                <label htmlFor="confirmPassword">
                  Confirmar Senha
                  <Input
                    w={'100%'}
                    borderColor={'grey'}
                    type={'password'}
                    maxLength={14}
                    aria-label="confirmPassword"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value.trim())}
                  />
                </label>
              </InputGroup>
            </FormControl>
          </Box>

          <Box mt={'30px'}>
            <Text fontWeight={'medium'}>
              Sua senha deve atender as seguintes regras abaixo: <br />
            </Text>
            <Text color={hasEightCharacters ? 'green' : 'red'}>
              Conter pelo menos 8 caracteres <br />
            </Text>
            <Text color={hasUpperCase ? 'green' : 'red'}>
              Conter letras maiúsculas <br />
            </Text>
            <Text color={hasLowerCase ? 'green' : 'red'}>
              Conter letras minúsculas <br />
            </Text>
            <Text color={hasNumbers ? 'green' : 'red'}>
              Conter números <br />
            </Text>
            <Text color={hasSpecialChar ? 'green' : 'red'}>
              Conter pelo menos 1 caractere especial (@#$&+=-), exceto %
            </Text>
          </Box>
          <Flex
            paddingTop={'20px'}
            justifyContent={'flex-end'}
            alignItems={'flex-end'}
            flexDirection={'row'}
          >
            <Button
              fontWeight="600"
              m='6px'
              onClick={() => {
                navigate('/login')
              }}
            >
              Cancelar
            </Button>{' '}
            <Button
              type="submit"
              onClick={async () => {
                if (isPasswordValid && isConfirmPasswordValid && data) {
                  try {
                    const res = await newPassword(
                      data.dsc_token!,
                      confirmPassword,
                      data.seq_usuario!
                    );
                    if (res.status === 200) {
                      setModalSuccessMessage('Senha alterada com sucesso.');
                      setShowSuccessModal(true);
                    } else {
                      setModalErrorMessage('Erro ao alterar senha. Tente novamente.');
                      setShowErrorModal(true);
                    }
                  } catch (error) {
                    console.error('Erro ao fazer a solicitação:', error);
                    setModalErrorMessage('Erro ao fazer a solicitação. Tente novamente.');
                    setShowErrorModal(true);
                  }
                }
              }}
              m="6px"
              color="#FFF"
              bgColor="#1665D8"
              _hover={{ opacity: 0.8 }}
              isDisabled={!isPasswordValid || !isConfirmPasswordValid || !data}
            >
              Alterar senha
            </Button>
          </Flex>
        </Box>
      </Container>
      <ModalError
        isOpen={showErrorModal}
        onClose={handleErrorClose}
        onConfirm={handleErrorClose}
        message={modalErrorMessage}
      />
      <ModalSuccess
        isOpen={showSuccessModal}
        onClose={handleSuccessClose}
        onConfirm={handleSuccessClose}
        message={modalSuccessMessage}
      />
    </>
  )
}

export default ChangePassword
