import { api } from '.'

export interface ApiComunication {
  communicationData: {
    serverStatus?: number
    dscRemetente?: string
    dscServEndereco?: string
    dscSenha?: string
    dscServPorta?: string
    dscUsuario?: string
  }[]
}

export const getComunication = async () => {
  try {
    const response = await api.get('/api/cms/communication')
    return response.data
  } catch (error) {
    console.error('Error fetching communication data:', error)
    throw error
  }
}

export const registerComunication = async (
  comunication: ApiComunication,
  token: string
) => {
  try {
    const response = await api.put('/api/cms/communication', comunication, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response.data
  } catch (error) {
    console.error('Error registering communication data:', error)
    throw error
  }
}

export const updateComunication = async (
  comunicationId: string,
  comunication: ApiComunication
) => {
  try {
    const response = await api.put(
      `api/comunication/${comunicationId}`,
      comunication
    )
    return response.data
  } catch (error) {
    console.error('Error updating communication data:', error)
    throw error
  }
}
