import React from 'react'

import { Flex, Text } from '@chakra-ui/react'

import { Video, Picture } from '../../../../components/Icons'

function InputMedia() {
  return (
    <>
      <Flex
        height="70vh"
        bgColor="#fff"
        border="1px dashed #3C8AF4"
        borderRadius="4px"
        boxShadow="0px 1px 3px rgba(0,0,0, .05)"
        justifyContent="center"
        alignItems="center"
      >
        <Flex transform="translateY(-50px)" gap="50px">
          <Flex flexDir="column" alignItems="center">
            <Picture fontSize="64px" color="#3C8AF4" />
            <Text color="#6F6F6F" fontSize="12px">
              {' '}
              Imagem{' '}
            </Text>
          </Flex>
          <Flex flexDir="column" alignItems="center">
            <Video fontSize="64px" color="#3C8AF4" />
            <Text color="#6F6F6F" fontSize="12px">
              {' '}
              Video{' '}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default InputMedia
