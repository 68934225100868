import { Flex, Icon } from '@chakra-ui/react'
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom'

function NewAction({ pageName }: any) {
  const navigate = useNavigate()
  return (
    <Flex color="#1789fc" onClick={() => navigate(`acoes/${pageName}`)}>
      <Icon as={MdEdit} fontSize="20px" marginLeft={25} style={{ cursor: "pointer" }} />
    </Flex>
  );
}

export default NewAction
