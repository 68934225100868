import { api } from './';

interface ApiError {
  response?: {
    data: any;
    status: number;
    statusText: string;
  };
}

export const inserirUsuario = async (params: any) => {
  try {
    const response = await api.post('/perfis/inserir', params);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao comunicar com o servidor');
  }
};

export const editarUsuario = async (id: number, params: any) => {
  try {
    const response = await api.put(`/perfis/editar/${id}`, params);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao comunicar com o servidor');
  }
};

export const obterUsuario = async (id: any) => {
  try {
    const response = await api.get(`/perfis/obter/${id}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao obter usuario');
  }
};

export const listarUsuarios = async (page: number, size: number) => {
  try {
    const response = await api.get(`/perfis/listar?pagina=${page}&tamanhoPagina=${size}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao comunicar com o servidor');
  }
};