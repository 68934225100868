import { Box, Flex, Heading } from '@chakra-ui/react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'

import SelectDate from '../SelectDate'

const data = [
  {
    name: 'A',
    uv: 4000,
    pv: 2400
  },
  {
    name: 'B',
    uv: 3000,
    pv: 1398
  },
  {
    name: 'C',
    uv: 2000,
    pv: 9800
  },
  {
    name: 'D',
    uv: 2780,
    pv: 3908
  },
  {
    name: 'E',
    uv: 1890,
    pv: 4800
  },
  {
    name: 'F',
    uv: 2390,
    pv: 3800
  },
  {
    name: 'G',
    uv: 3490,
    pv: 4300
  },
  {
    name: 'A',
    uv: 4000,
    pv: 2400
  },
  {
    name: 'B',
    uv: 3000,
    pv: 1398
  },
  {
    name: 'C',
    uv: 2000,
    pv: 9800
  },
  {
    name: 'D',
    uv: 2780,
    pv: 3908
  },
  {
    name: 'E',
    uv: 1890,
    pv: 4800
  },
  {
    name: 'F',
    uv: 2390,
    pv: 3800
  },
  {
    name: 'G',
    uv: 3490,
    pv: 4300
  },
  {
    name: 'A',
    uv: 4000,
    pv: 2400
  },
  {
    name: 'B',
    uv: 3000,
    pv: 1398
  },
  {
    name: 'C',
    uv: 2000,
    pv: 9800
  },
  {
    name: 'D',
    uv: 2780,
    pv: 3908
  },
  {
    name: 'E',
    uv: 1890,
    pv: 4800
  },
  {
    name: 'F',
    uv: 2390,
    pv: 3800
  },
  {
    name: 'G',
    uv: 3490,
    pv: 4300
  }
]

function DailyVisitors() {
  return (
    <Box
      border="1px solid #EAEDF3"
      borderRadius="4px"
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="25px 0"
      backgroundColor="#fff"
    >
      <Flex
        p="0 25px"
        w="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Heading fontSize="14px" fontWeight="600" color="#3E3F42">
          Visitantes diarios
        </Heading>
        <SelectDate />
      </Flex>
      <BarChart
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        width={950}
        height={300}
        data={data}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          tickMargin={5}
          dataKey="name"
          padding={{ left: 10, right: 10 }}
        />
        <YAxis hide />
        <Tooltip />
        <Bar barSize={14} dataKey="pv" fill="#1665D8" />
      </BarChart>
    </Box>
  )
}

export default DailyVisitors
