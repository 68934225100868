import React, { useEffect } from 'react';
import { useState } from 'react';
import { Box } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
  ],
};

const TextEditor = ({ value, onChange }: any) => {
  const [editorValue, setEditorValue] = useState(value);
  const [charCount, setCharCount] = useState(value.length);

  const editorStyle = {
    height: '290px',
  };

  const handleChange = (content: string) => {
    const plainText = convertToPlainText(content);
    setEditorValue(content);
    onChange(plainText);
    setCharCount(content.length);
  };
  

  const convertToPlainText = (html: string) => {
    const tempDivElement = document.createElement('div');
    tempDivElement.innerHTML = html;
    const plainText = tempDivElement.textContent || tempDivElement.innerText || '';
    return plainText.replace(/<\/?[^>]+(>|$)/g, '');
  };

  useEffect(() => {
    //console.log(convertToPlainText(editorValue));
  }, [editorValue]);

  return (
    <Box color="black">
      <ReactQuill
        theme="snow"
        value={editorValue}
        onChange={handleChange}
        modules={modules}
        style={editorStyle}
        //dangerousLySetInnerHTML={{ __html: value }}
      />
    </Box>
  );
};

export default TextEditor;
