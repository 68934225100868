import React, { useState } from "react";
import SettingsBreadcrumb from "../../../../components/SettingsBreadcrumb";
import { useNavigate } from "react-router-dom";
import {
  Heading,
  Flex,
  GridItem,
  Textarea,
  Input,
  Box,
  Button,
} from "@chakra-ui/react";

export default function CreateVideo() {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [showChangeButton, setShowChangeButton] = useState(false);
  const [videoPreview, setVideoPreview] = useState(null||'');
  const [textBox, setTextBox] = useState('');

  const handleTitleChange = (event: any) => {
    setTitle(event.target.value);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setVideoFile(file);
    setShowChangeButton(true);
    setVideoPreview(URL.createObjectURL(file));
  };

  const handleEditorChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextBox(event.target.value);
  };  

  const handleChangeVideo = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "video/*";
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      setVideoFile(file);
      setVideoPreview(URL.createObjectURL(file));
    };
    input.click();
  };
  

  const handleSubmit = () => {
    if (videoFile) {
      console.log('Titulo: ', title);
      console.log('Arquivo de vídeo: ', videoFile);
      console.log('Descrição: ', textBox)
    }
  };

  return (
    <Flex h="100%" p="40px" bgColor="#fafafa" direction="column">
      {/* <SettingsBreadcrumb /> */}
      <Heading fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Inserir vídeo
      </Heading>

      <Heading
        pt="24px"
        pb="32px"
        fontWeight="600"
        fontSize="20px"
        color="#1664D7"
      >
        {" "}
        Adicione um Título
      </Heading>
      <Input
        type="text"
        mb={30}
        value={title}
        placeholder={"Digite o Título"}
        onChange={handleTitleChange}
      />

      <Box
        border="2px dashed #ccc"
        borderRadius="5px"
        p="40px"
        textAlign="center"
      >
        {videoFile ? (
          <div>
            <video
              className="VideoInput_video"
              width="100%"
              height={200}
              controls
              src={videoPreview}
            />
            <p>Arquivo de vídeo selecionado: {videoFile.name}</p>
            {/* <p>{videoFile.name}</p> */}
            {showChangeButton && (
              <Button mt={2} colorScheme="blue" onClick={handleChangeVideo}>
                Alterar vídeo
              </Button>
            )}
          </div>
        ) : (
          <div>
            <p>Arraste o vídeo aqui ou clique para selecionar</p>
            <input type="file" accept="video/*" onChange={handleFileChange} />
          </div>
        )}
      </Box>
      <Heading
        pt="24px"
        pb="32px"
        fontWeight="600"
        fontSize="20px"
        color="#1664D7"
      >
        {" "}
        Adicione uma descrição do vídeo
      </Heading>
      <Textarea
        height={100}
        value={textBox}
        onChange={handleEditorChange}
      ></Textarea>
      <Box mt="auto" ml="auto">
        <Button type="submit" m="px" onClick={() => navigate(-1)}>
          Cancelar
        </Button>
        <Button
          type="submit"
          m="6px"
          color="#FFF"
          bgColor="#1665D8"
          _hover={{ opacity: 0.8 }}
          onClick={() => {
            handleSubmit();
            navigate('/cms/content');
          }} 
          disabled={!videoFile || !videoPreview || !title}
        >
          Salvar
        </Button>
      </Box>
    </Flex>
  );
}
