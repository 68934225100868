import { Flex, Heading, Button, Grid, GridItem, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Box, RadioGroup, Radio } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb';
import CustomInput from '../../../../components/common/Input/input';
import * as api from '../../../../api/usuario';

function EditarUsuario() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  const [formValues, setFormValues] = useState({
    dscPerfil: { value: '', error: false },
    numRegistro: { value: 0, error: false }
  });

  const fetchUserData = async () => {
    try {
      const userData = await api.obterUsuario(id);
      setFormValues({
        dscPerfil: { value: userData.dscPerfilAcesso, error: false },
        numRegistro: { value: userData.numRegistro, error: false }
      });
    } catch (error) {
      setErrorMessage('Erro ao obter dados do usuário.');
      setIsErrorModalOpen(true);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [id]);

  const isFormValid = () => {
    const { dscPerfil } = formValues;
    return dscPerfil.value.trim() && dscPerfil.value.length >= 3;
  };

  const handleFieldChange = (field: any, value: any) => {
    setFormValues({
      ...formValues,
      [field]: { error: false, value }
    });
  };

  const handleSubmit = async () => {
    const { dscPerfil, numRegistro } = formValues;
  
    const params = {
      codPerfilAcesso: Number(id),
      dscPerfilAcesso: dscPerfil.value || '',
      numRegistro: numRegistro.value
    };
  
    try {
      await api.editarUsuario(Number(id), params);
      setIsSuccessModalOpen(true);
    } catch (error) {
      setErrorMessage('Erro ao editar perfil de usuário. Tente novamente.');
      setIsErrorModalOpen(true);
    }
  };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" mb="20px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Editar Perfil de Usuário
      </Heading>
      <div className="row mb-3">
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Perfil</FormLabel>
            <CustomInput
              value={formValues.dscPerfil.value}
              onChange={(e) => handleFieldChange('dscPerfil', e.target.value)}
              maxLength={60}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Situação</FormLabel>
            <RadioGroup
              mt={3.5}
              onChange={(value) => handleFieldChange('numRegistro', Number(value))}
              value={formValues.numRegistro.value.toString()}
            >
              <Radio value="1" mr={5}>Ativo</Radio>
              <Radio value="0">Inativo</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <Box alignItems={'flex-end'}>
        <Button m="px" onClick={() => navigate(-1)}>
          Desistir
        </Button>
        <Button
          m="6px"
          color="#FFF"
          bgColor="#1665D8"
          _hover={{ opacity: 0.8 }}
          onClick={handleSubmit}
          isDisabled={!isFormValid()}
        >
          Alterar o Perfil
        </Button>
      </Box>

      <Modal isOpen={isSuccessModalOpen} onClose={() => {
        setIsSuccessModalOpen(false);
        navigate('/cms/noticia');
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sucesso!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Perfil de usuário editado com sucesso!</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => {
              setIsSuccessModalOpen(false);
              navigate('/cms/noticia');
            }}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atenção!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{errorMessage}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => setIsErrorModalOpen(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default EditarUsuario;
