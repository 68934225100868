import { Icon, IconProps } from '@chakra-ui/react'
const PictureTsx = (props: IconProps) => (
  <Icon viewBox="0 0 51.189 37.575" {...props}>
    <g id="picture" transform="translate(0.8 0.8)">
      <g
        id="Grupo_23106"
        data-name="Grupo 23106"
        transform="translate(6.167 6.203)"
      >
        <ellipse
          id="Elipse_69"
          data-name="Elipse 69"
          cx={4.199}
          cy={4.199}
          rx={4.199}
          ry={4.199}
          transform="translate(28.949 0)"
          fill="none"
          stroke="#8ab1eb"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.6}
        />
        <path
          id="Caminho_67992"
          data-name="Caminho 67992"
          d="M2546,765.306,2557.306,754l15.418,14.39"
          transform="translate(-2546 -745.813)"
          fill="none"
          stroke="#8ab1eb"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.6}
        />
        <path
          id="Caminho_67993"
          data-name="Caminho 67993"
          d="M2660.423,794.1,2647.139,782,2642,787.139"
          transform="translate(-2617.332 -766.618)"
          fill="none"
          stroke="#8ab1eb"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.6}
        />
      </g>
      <path
        id="Caminho_67994"
        data-name="Caminho 67994"
        d="M2568.287,733.975H2525.05a3.006,3.006,0,0,1-3.05-2.96V700.96a3.006,3.006,0,0,1,3.05-2.96h43.236a3.006,3.006,0,0,1,3.05,2.96v30.054A3.006,3.006,0,0,1,2568.287,733.975Z"
        transform="translate(-2522 -698)"
        fill="none"
        stroke="#1665d8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.6}
      />
    </g>
  </Icon>
)

export default PictureTsx
