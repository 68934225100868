import * as React from 'react'

import { Heading, Flex, Grid, GridItem } from '@chakra-ui/react'

import withAuth from '../../contexts/AuthContext/withAuth'
import InputMedia from './components/InputMedia'
import PostConfiguration from './components/PostConfiguration'
import PostDescription from './components/PostDescription'
import SelectProfile from './components/SelectProfile'

function SocialMedia() {
  return (
    <Flex p="40px" bgColor="#fafafa" direction="column">
      <Heading fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Agendamento de Posts
      </Heading>
      <SelectProfile />
      <Grid w="100%" gridTemplateColumns="4fr 4fr 4fr" gap="35px">
        <GridItem>
          <InputMedia />
        </GridItem>
        <GridItem>
          <PostDescription />
        </GridItem>
        <GridItem>
          <PostConfiguration />
        </GridItem>
      </Grid>
    </Flex>
  )
}

export default withAuth(SocialMedia)
