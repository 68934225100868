import {
  Flex,
  Icon,
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  ModalOverlay,
  Text,
  ModalBody,
  Box,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  keyframes
} from '@chakra-ui/react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

interface ModalSuccessProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  message: string;
}

export const ModalSuccess = ({
  isOpen,
  onClose,
  onConfirm,
  message
}: ModalSuccessProps) => {
  
  const navigate = useNavigate()

  const slideDown = keyframes`
    0% { transform: translateY(-50px); }
    100% { transform: translateY(0); }
  `;

  return (
    <>
      <Modal
        size="sm"
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false} 
      >
        <ModalOverlay />
        <ModalContent
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p="12px 16px"
          mx="auto"  
          my="auto"
        >
          <ModalBody
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Icon
              as={AiOutlineCheckCircle}
              fontSize={60}
              color="#2ccc74"
              mb="10px"
              css={{
                animation: `${slideDown} 0.5s ease-out`
              }}
            />
            <Box mb="20px">
              {message}
            </Box>
            <Flex justifyContent="center">
              {onConfirm && (
                <Button
                  w={''}
                  type="submit"
                  onClick={() => {
                    navigate('/login')
                  }}
                  m="6px"
                  color="#FFF"
                  bgColor="#1665D8"
                  _hover={{ opacity: 0.8 }}
                >
                  OK
                </Button>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
