import React from 'react'

import { Box } from '@chakra-ui/react'

import AppRoutes from '../routes'

function Pages() {
  return (
    <Box w="100%" h="calc(100vh)" color="black" bgColor="#fafafa" zIndex={-1}>
      <AppRoutes />
    </Box>
  )
}

export default Pages
