import { IconProps, Icon } from '@chakra-ui/react'
const Video = (props: IconProps) => (
  <Icon viewBox="0 0 50.937 37.575" {...props}>
    <g id="video" transform="translate(0.8 0.8)">
      <path
        id="Caminho_67995"
        data-name="Caminho 67995"
        d="M3020.286,733.975H2977.05a3.006,3.006,0,0,1-3.05-2.96V700.96a3.006,3.006,0,0,1,3.05-2.96h43.236a3.006,3.006,0,0,1,3.05,2.96v30.054A3.006,3.006,0,0,1,3020.286,733.975Z"
        transform="translate(-2974 -698)"
        fill="none"
        stroke="#1665d8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.6}
      />
      <g
        id="Grupo_23108"
        data-name="Grupo 23108"
        transform="translate(6.871 9.251)"
      >
        <g
          id="Grupo_23107"
          data-name="Grupo 23107"
          transform="translate(0 17.563)"
        >
          <line
            id="Linha_112"
            data-name="Linha 112"
            x2={35.116}
            transform="translate(0 2.29)"
            fill="none"
            stroke="#8ab1eb"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.6}
          />
          <line
            id="Linha_113"
            data-name="Linha 113"
            y2={3.817}
            transform="translate(4.58 0)"
            fill="none"
            stroke="#8ab1eb"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.6}
          />
        </g>
        <path
          id="Caminho_67996"
          data-name="Caminho 67996"
          d="M3050,734v12.334l12.334-6.167Z"
          transform="translate(-3037.341 -734)"
          fill="none"
          stroke="#8ab1eb"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.6}
        />
      </g>
    </g>
  </Icon>
)

export default Video
