import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIMask } from 'react-imask'

import {
  Container,
  Box,
  Img,
  Input,
  Stack,
  FormControl,
  InputGroup,
  FormHelperText,
  FormErrorMessage,
  Link,
  Button,
  useToast,
  Text,
  Flex,
  Heading
} from '@chakra-ui/react'

import mailLetter from '../../assets/mail-2558.svg'
import { useAuth } from '../../contexts/AuthContext'

const AfterPage = () => {
  useEffect(() => {
    console.log(`Ambiente: ${process.env.NODE_ENV}`)
  }, [])

  const toast = useToast()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({
    cpf: {
      value: '',
      error: false
    },
    email: {
      value: '',
      error: false
    }
  })

  const handleFieldChange = (field: string, value: string) =>
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    })

  const { ref: cpfRef } = useIMask(
    { mask: '000.000.000-00' },
    {
      onAccept: (value) => handleFieldChange('cpf', value)
    }
  )

  const isValidateForm = () => {
    let isValid = true
    if (!formValues.cpf.value) {
      setFormValues({
        ...formValues,
        cpf: { ...formValues.cpf, error: true }
      })
      isValid = false
    }

    if (!formValues.email.value) {
      setFormValues({
        ...formValues,
        email: { ...formValues.email, error: true }
      })
      isValid = false
    }

    return isValid
  }

  const handleSubmit = async (e: any) => {
    const cpf = formValues.cpf.value.replace(/\.|-/gm, '')
    const email = formValues.email.value
    e.preventDefault()

    if (isValidateForm()) {
      const res = [cpf, email]
      console.log(res)
      // if (!res.error) {
      //   console.log('eroor')
      //   // navigate('/cms')
      // } else {
      //   toast({
      //     title: res.data,
      //     description: '',
      //     status: 'error',
      //     duration: 5000,
      //     isClosable: true
      //   })
      // }
    }
  }

  return (
    <Container
      overflowX="auto"
      color="#000"
      bgColor="#EDEDED"
      display="flex"
      alignItems="center"
      justifyContent="center"
      w="100%"
      maxWidth="100%"
      maxHeight="100%"
      height="100vh"
      p="0"
      m="0"
    >
      {/* <Box
        width="50%"
        // height={'100%'}
        boxShadow="3px 3px 3px rgba(0,0,0, .2)"
        bgColor="#fff"
        borderRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p="0 40px"
        paddingBottom={'30px'}
      > */}
        <form onSubmit={handleSubmit}>
          <Stack spacing={4} w="100%">
            <Flex justifyContent="center" alignItems="center">
            <Img src={mailLetter} width={'200px'} ml={'62px'} mb={'-40px'}/>
            </Flex>
            <Heading fontWeight="600" textAlign={'center'} fontSize="36px" w="100%">
              Tudo pronto!
            </Heading>
            <Text color={'grey'} textAlign={'center'}>
              Verifique seu endereço de Email para concluir o processo de 
              recuperação de senha da sua conta.
            </Text>
            <Flex
              paddingTop={'20px'}
              justifyContent={'center'}
              alignItems={'center'}
              flexDirection={'row'}
            >
              <Button
                w={''}
                type="submit"
                onClick={() => {
                  navigate('/login')
                }}
                m="6px"
                color="#FFF"
                bgColor="#1665D8"
                _hover={{ opacity: 0.8 }}
              >
                  OK, entendi!
              </Button>
            </Flex>
          </Stack>
        </form>
      {/* </Box> */}
    </Container>
  )
}

export default AfterPage
