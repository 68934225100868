import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

interface ImageDropzoneProps {
  onImageSelected: (selected: File | null) => void;
  base64: string;
  width?: number;
  height?: number;
  uploadMessage?: string;
}

const DropContainer = styled.div<{ isDragActive: boolean; isDragReject: boolean }>`
  border: 2px dashed #cccccc;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.isDragActive ? "#e0f7fd" : props.isDragReject ? "#ffd7d7" : "white"};
  &:hover {
    border-color: #3884cc;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const UploadedImage = styled.img<{ width?: number; height?: number }>`
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  object-fit: cover;
`;

const ChangeImageButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border: none;
  padding: 5px;
  cursor: pointer;
`;

const UploadMessage = styled.p`
  text-align: center;
  font-size: 16px;
  color: #999999;
  &:hover {
    color: #3884cc;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const ImageDropzone: React.FC<ImageDropzoneProps> = ({ onImageSelected, base64, width, height, uploadMessage }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(base64);
  const [error, setError] = useState<string | null>(null);

  const convertBase64 = async (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const validateImageDimensions = (file: File): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const url = URL.createObjectURL(file);
      img.src = url;
  
      img.onload = () => {
        const { width, height } = img;
        URL.revokeObjectURL(url);
        resolve({ width, height });
      };
  
      img.onerror = () => {
        URL.revokeObjectURL(url);
        reject(new Error('Falha ao carregar a imagem.'));
      };
    });
  };

  const handleDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 1) {
      setError("Você só pode enviar uma imagem.");
      setSelectedImage(null);
      onImageSelected(null);
    } else if (acceptedFiles.length === 1) {
      setError(null);
      const base64 = await convertBase64(acceptedFiles[0]);
      setSelectedImage(base64);
      onImageSelected(acceptedFiles[0]);
    }
  }, [onImageSelected]);

  const handleChangeImage = () => {
    setSelectedImage(null);
    setError(null);
    onImageSelected(null);
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    },
    onDrop: handleDrop,
  });

  return (
    <DropContainer {...getRootProps({ isDragActive, isDragReject })}>
      <input {...getInputProps()} />
      {selectedImage ? (
        <ImageContainer>
          <UploadedImage src={selectedImage} width={width} height={height} />
          <ChangeImageButton onClick={handleChangeImage}>
            Alterar imagem
          </ChangeImageButton>
        </ImageContainer>
      ) : isDragActive ? (
        <UploadMessage>{uploadMessage || "Arraste o arquivo aqui..."}</UploadMessage> // Usar a mensagem personalizada ou a padrão
      ) : (
        <UploadMessage
          dangerouslySetInnerHTML={{
            __html: uploadMessage || "Arraste a imagem do banner aqui ou clique para selecionar.<br/>(máx. 300x300 pixels)",
          }}
        />
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </DropContainer>
  );
};

export { ImageDropzone };
