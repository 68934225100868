import { Menu, MenuButton, MenuList, MenuItem, Box } from '@chakra-ui/react'

function SelectDate() {
  return (
    <Box
      m="0"
      w="20%"
      bgColor="#fff"
      borderRadius="4px"
      border="1px solid #EAEDF3"
    >
      <Menu>
        <MenuButton
          px={4}
          py={2}
          transition="all 0.2s"
          borderRadius="md"
          borderWidth="1px"
          color="#3E3F42"
          fontSize="12px"
          w="100%"
          border="1px solid #EAEDF3"
          // _hover={{ bg: 'gray.400' }}
          // _expanded={{ bg: 'blue.400' }}
          // _focus={{ boxShadow: 'outline' }}
        >
          Selecione o mes
        </MenuButton>
        <MenuList>
          <MenuItem>-</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  )
}

export default SelectDate
