import { Input, InputProps } from '@chakra-ui/react';

const CustomInput: React.FC<InputProps> = (props) => {
  return (
    <Input
      variant="outline"
      backgroundColor={'#fff'}
      maxLength={100}
      borderColor="gray.300"
      _hover={{
        borderColor: 'blue.500',
      }}
      _focus={{
        borderColor: 'blue.500',
        boxShadow: '0 0 0 1px blue.500',
      }}
      {...props}
    />
  );
};

export default CustomInput;
