import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface Noticia {
  seqConteudo: number;
  nomePublicacao: string;
  datRegistro: string;
  dataPublicacao: string;
  dataFimPublicacao: string;
}

interface TabelaNoticiaProps {
  listaNoticia: Noticia[];
  listaVazia: string;
}

const TabelaNoticia = ({ listaNoticia, listaVazia }: TabelaNoticiaProps) => {
  const navigate = useNavigate();

  const renderRows = () =>
    listaNoticia.length > 0 ? (
      listaNoticia.map((noticia) => (
        <Tr key={noticia.seqConteudo}>
          <Td>{noticia.nomePublicacao}</Td>
          <Td>{new Date(noticia.datRegistro).toLocaleDateString('pt-BR')}</Td>
          <Td>{new Date(noticia.dataPublicacao).toLocaleDateString('pt-BR')}</Td>
          <Td>{new Date(noticia.dataFimPublicacao).toLocaleDateString('pt-BR')}</Td>
          <Td>
            <IconButton
              aria-label="Botão Editar"
              bgColor="#fff"
              onClick={() => navigate(`editar-noticia/${noticia.seqConteudo}`)}
            >
              <Icon as={MdEdit} fontSize="24px" color="#1789FC" />
            </IconButton>
          </Td>
        </Tr>
      ))
    ) : (
      <Tr>
        <Td colSpan={5} textAlign="center">{listaVazia}</Td>
      </Tr>
    );

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Título do notícia</Th>
            <Th>Data do Registro</Th>
            <Th>Data Para Publicação</Th>
            <Th>Data Fim da Publicação</Th>
            <Th>Editar</Th>
          </Tr>
        </Thead>
        <Tbody>{renderRows()}</Tbody>
      </Table>
    </TableContainer>
  );
};

export default TabelaNoticia;
