import { useEffect, useState } from 'react'

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Text
} from '@chakra-ui/react'

function CreatePassword() {
  const [formValues, setFormValues] = useState({
    newPassword: {
      value: '',
      error: false
    },
    confirmPassword: {
      value: '',
      error: false
    }
  })
  const [passwordValidation, setPasswordValidation] = useState({
    minimumLength: false,
    numericPattern: false,
    specialCharacter: false,
    upperCase: false,
    downCase: false
  })

  useEffect(() => {
    validatePassword(formValues.newPassword.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.newPassword.value])

  const handleFieldChange = (field: string, value: string) =>
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    })

  const validatePassword = (password: string) => {
    const minimumLengthPattern = /^.{8,}$/
    const containsNumericPattern = /\d/
    const containsSpecialCharacterPattern = /[!@#$%^&*=+]/
    const containsUppercasePattern = /[A-Z]/
    const containsLowercasePattern = /[a-z]/

    setPasswordValidation({
      minimumLength: minimumLengthPattern.test(password),
      numericPattern: containsNumericPattern.test(password),
      specialCharacter: containsSpecialCharacterPattern.test(password),
      upperCase: containsUppercasePattern.test(password),
      downCase: containsLowercasePattern.test(password)
    })
    // Check the individual requirements and provide feedback
  }
  return (
    <Flex
      w="100%"
      h="calc(100vh)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        width="65%"
        h="calc(75vh)"
        boxShadow="3px 3px 3px rgba(0,0,0, .2)"
        bgColor="#fff"
        borderRadius="4px"
        alignItems="start"
        flexDir="column"
        justifyContent="start"
        p="75px 40px"
      >
        <Box mb="32px">
          <Heading mb="12px">Alterar Senha</Heading>
          <Text opacity="0.6">
            Confirme seus dados e defina sua nova senha fornecendo no minimo 8
            caracteres
          </Text>
        </Box>
        <SimpleGrid columns={2} width="100%" h="100%">
          <Flex
            bgColor="#e6e6e6"
            flexDir="column"
            alignContent="center"
            justifyContent="center"
            p="16px"
          >
            <Flex my="8px" flexDir="row">
              <Text as="b" mr="6px">
                Nome:{' '}
              </Text>
              <Text>Pedro Henrique</Text>
            </Flex>
            <Flex my="8px" flexDir="row">
              <Text as="b" mr="6px">
                CPF:{' '}
              </Text>
              <Text>093.503..999-62</Text>
            </Flex>
            <Flex my="8px" flexDir="row">
              <Text as="b" mr="6px">
                E-mail:{' '}
              </Text>
              <Text>teste@gmail.com.br</Text>
            </Flex>
            <Flex my="8px" flexDir="row">
              <Text as="b" mr="6px">
                Celular:{' '}
              </Text>
              <Text>(41)98374-2333</Text>
            </Flex>
          </Flex>
          <Flex m="16px" flexDir="column" justifyContent="center">
            <FormControl mb="12px" isInvalid={formValues.newPassword.error}>
              <FormLabel>Nova Senha</FormLabel>
              <Input
                type="password"
                value={formValues.newPassword.value}
                onChange={(e) =>
                  handleFieldChange('newPassword', e.target.value.trim())
                }
              />
            </FormControl>
            <FormControl isInvalid={formValues.confirmPassword.error}>
              <FormLabel>Confirmar Senha</FormLabel>
              <Input
                type="password"
                value={formValues.confirmPassword.value}
                onChange={(e) =>
                  handleFieldChange('confirmPassword', e.target.value.trim())
                }
              />
            </FormControl>
            <Box my="16px" color="#FF0000">
              {Object.keys(passwordValidation).find(
                (p) => !passwordValidation[p as keyof typeof passwordValidation]
              ) && (
                <Heading my="6px" fontSize="12px" as="h5">
                  Sua senha deve atender as seguintes regras
                </Heading>
              )}
              {!passwordValidation.minimumLength && (
                <Text my="6px" fontSize="11px">
                  Conter pelo menos 8 caracteres
                </Text>
              )}
              {!passwordValidation.upperCase && (
                <Text my="6px" fontSize="11px">
                  Conter letras maiúsculas
                </Text>
              )}
              {!passwordValidation.downCase && (
                <Text my="6px" fontSize="11px">
                  Conter letras minúsculas
                </Text>
              )}
              {!passwordValidation.numericPattern && (
                <Text my="6px" fontSize="11px">
                  Conter números
                </Text>
              )}
              {!passwordValidation.specialCharacter && (
                <Text my="6px" fontSize="11px">
                  Conter pelo menos 1 caracter especial `(@#$%&+=)`
                </Text>
              )}
            </Box>
          </Flex>
        </SimpleGrid>
      </Flex>
    </Flex>
  )
}

export default CreatePassword
