import React, { useState } from 'react';
import {
  Flex,
  Text,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react';

interface FilterProps {
  onApplyFilter: (filter: string) => void;
  onClearFilter: () => void;
}

const Filter: React.FC<FilterProps> = ({ onApplyFilter, onClearFilter }) => {
  const [filter, setFilter] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuButtonClick = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handleApplyFilter = () => {
    onApplyFilter(filter);
    setIsMenuOpen(false);
  };

  const handleClearFilter = () => {
    setFilter('');
    onClearFilter();
    setIsMenuOpen(false);
  };

  return (
    <Flex align="center">
      <Text mr={3}>Aplicar Filtro:</Text>
      <Menu closeOnSelect={false} isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <MenuButton as={Button} bgColor={'#edf2f7'} onClick={handleMenuButtonClick}>
          Situação
        </MenuButton>
        <MenuList minWidth="240px">
          <MenuOptionGroup type="radio" value={filter} onChange={(value: any) => setFilter(value)}>
            <MenuItemOption value="active">Ativo</MenuItemOption>
            <MenuItemOption value="inactive">Inativo</MenuItemOption>
          </MenuOptionGroup>
          <MenuDivider />
          <MenuOptionGroup as={Button} gap={5}>
            <Flex gap={2} justifyContent="center">
              <Button onClick={handleClearFilter}>
                Limpar
              </Button>
              <Button onClick={handleApplyFilter}> 
                Aplicar
              </Button>
            </Flex>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default Filter;
