import { useState } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'

import {
  Flex,
  Icon,
  Modal,
  ModalContent,
  ModalFooter,
  Button,
  ModalOverlay,
  Text,
  GridItem,
  ModalBody,
  Box,
  useDisclosure,
  ModalHeader,
  ModalCloseButton
} from '@chakra-ui/react'

import { ModalConfirm } from './modalConfirm'

interface ModalDeleteNewsProps {
  isOpen: boolean
  children: React.ReactNode
  onClose: () => void
  header?: React.ReactNode
  footer?: React.ReactNode
}

export const ModalDeleteNews = ({
  header,
  footer,
  children,
  isOpen,
  onClose
}: ModalDeleteNewsProps) => {
  const [confirmButton, setConfirmButton] = useState()

  return (
    <>
      <Modal size="sm" isOpen={isOpen} onClose={onClose}>
        {/* <ModalOverlay /> */}
        <ModalContent display="flex" alignContent="center" p="12px 16px">
          <ModalHeader
            height="100px"
            flexDir="column"
            display="flex"
            alignItems="center"
          >
            <Icon
              as={AiOutlineInfoCircle}
              fontSize={60}
              color="#1789fc"
              mb="10px"
            />
            Deletar esta notícia?
          </ModalHeader>
          <ModalBody flexDir="column" display="flex" alignItems="center">
            {children}
            <Flex justifyContent="end">
              <Button
                type="submit"
                m="6px"
                color="#FFF"
                bgColor="#1665D8"
                value={confirmButton}
                _hover={{ opacity: 0.8 }}
                // onClick={() => {
                //   return (
                //
                //     <ModalConfirm isOpen={isOpen} onClose={onClose}>
                //
                //     </ModalConfirm>
                //   )
                // }}
                onClick={onClose}
              >
                Sim
              </Button>
              {/* <GridItem>
                {confirmButton ? <ModalConfirm isOpen={isOpen} onClose={onClose}>
                
               </ModalConfirm>
               : <div></div>}
              </GridItem> */}
              <Button type="submit" m="6px" onClick={onClose}>
                Não
              </Button>
            </Flex>
          </ModalBody>
          {footer ? <ModalFooter>{footer}</ModalFooter> : null}
        </ModalContent>
      </Modal>
    </>
  )
}
