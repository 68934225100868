import { api } from './'

export const validateUser = async (cpf: string, email: string) => {
  return await api.post('/auth/validateUser', {
    cpf,
    email
  })
}

export const newPassword = async (token: string, newPassword: string, seqUsuario: number) => {
  return await api.post('/auth/password', {
    token,
    newPassword,
    seqUsuario
  });
}

