import React, { useState, useEffect } from 'react';
import { Input, Heading, Grid, GridItem, Button, Icon, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import NewAction from '../Actions/Icon';
import {  MdOutlineArrowBackIos } from 'react-icons/md';
import Filter from './filter'
import { api } from '../../../../api'
import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Stack, Text, HStack, Select, } from '@chakra-ui/react';

export interface DataItem {
  seq_pagina: number;
  nom_pagina: string; 
  sit_pagina: number | string; 
  sit_registro: number;
}

export interface PageData {
  id: number;
  pageName: string;
  action: JSX.Element;
  situation: number;
  data: PageData[];
  filter: string;
  register: string | number
}

const PageTable: React.FC<PageData & { data: PageData[] }> = ({ id, pageName, action, situation, data }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState<string>(''); 
  const [filteredData, setFilteredData] = useState<PageData[]>([]);
  const [originalData, setOriginalData] = useState<PageData[]>([]);
  const [pageNumbers, setPageNumbers] = useState(1);

  useEffect(() => {
    const getPages = '/api/cms/manage'
    // busca os dados na API
    const fetchData = () => {
      api.get(getPages)
        .then(response => {
          const { data } = response;
          //console.log("dados da tabela: ", data)
  
          const pageDataArray: PageData[] = data.map((item: DataItem) => ({
            id: item.seq_pagina,
            pageName: item.nom_pagina,
            situation: Number(item.sit_pagina), 
            register: Number(item.sit_registro),
          }));
          
          setOriginalData(pageDataArray);
          setFilteredData(pageDataArray);
        })
        .catch(error => {
          console.error('Erro ao obter os dados do back-end:', error);
        });
    };
  
    const delayInMilliseconds = 150; 
    const timeout = setTimeout(() => {
      fetchData();
    }, delayInMilliseconds);
  
    return () => clearTimeout(timeout);
  }, []);
  
  const applyFilter = (newFilter: string) => {
    setFilter(newFilter);
    const filteredItems = originalData.filter((e) => {
      if (newFilter === 'active') {
        return e.situation === 1;
      } else if (newFilter === 'inactive') {
        return e.situation === 0;
      }
      return true; 
    });
    setFilteredData(filteredItems);
  };  

  const clearFilter = () => {
    setFilter('');
    setFilteredData(originalData);
  };

  const getSituationText = (value: number): string => {
    return value == 1 ? 'Ativo' : 'Inativo';
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  const filteredRows = filteredData.filter((e) =>
    searchTerm === '' || e.pageName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  //const pageNumbers = Math.ceil(originalData.length / rowsPerPage);

  useEffect(() => {
    setPageNumbers(Math.ceil(filteredRows.length / rowsPerPage));
    setCurrentPage(1);
  }, [filteredRows.length, rowsPerPage]);

  const currentRows = filteredRows.slice(indexOfFirstRow, Math.min(indexOfLastRow, filteredRows.length));
  
  const renderRows = () => {
    if (currentRows.length === 0) {
      return (
        <Tr>
          <Td colSpan={5} textAlign="center">Não há páginas existentes</Td>
        </Tr>
      );
    }
  
    return currentRows.map((e) => (
      <Tr key={e.id}>
        <Td maxWidth={14} fontSize={14}>
          {e.pageName}
        </Td>
        <Td fontSize={14}>{getSituationText(e.situation)}</Td>
        <Td>
          <NewAction pageName={e.pageName} />
        </Td>
      </Tr>
    ));
  };  

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(pageNumbers);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < pageNumbers) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFilter = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  return (
    <>
      <Grid templateColumns="1fr 1fr" alignItems="center">
        <GridItem>
          <Button
            bgColor="#1789FC"
            color="#fff"
            onClick={() => navigate('adicionar-pagina')}
            mb={8}
          >
            Incluir Nova Página
          </Button>
          <Flex>
          <Filter
            onApplyFilter={(filter: string): void => {
              applyFilter(filter);
            }}
            onClearFilter={(): void => {
              clearFilter();
            }}
          />
          </Flex>
        </GridItem>
        <GridItem>
          <Input
            width={'70%'}
            mb={75}
            type="text"
            onChange={handleFilter}
            value={searchTerm}
            placeholder="Pesquise o nome da página"
          />
        </GridItem>
      </Grid>
      <GridItem>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>
                  <Heading
                  mt="44px"
                  fontWeight="600"
                  fontSize="17px"
                  color="#6F6F6F"
                  w="100%"
                >
                  Páginas
                </Heading>
                </Th>
                <Th>
                  <Heading
                  mt="44px"
                  fontWeight="600"
                  fontSize="17px"
                  color="#6F6F6F"
                  w="100%"
                >
                  Situação
                </Heading>
                </Th>
                <Th><Heading
                  mt="44px"
                  fontWeight="600"
                  fontSize="17px"
                  color="#6F6F6F"
                  w="100%"
                >
                  Ações
                </Heading></Th>
              </Tr>
            </Thead>
            <Tbody>{renderRows()}</Tbody>
          </Table>
        </TableContainer>
        <Flex align="center" justify="center" mt={10}>
          <Stack direction="row" >
            <Flex align="center">
              <Text as="span" color={'#767676'} fontSize={13}>Itens por página</Text>
              <Select
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(Number(e.target.value))}
                maxWidth={71}
                fontSize={13}
                px={1}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
              </Select>
            </Flex>

            <HStack>
              <Text ml={15} fontSize={13} color={'#767676'}>
                Página {currentPage} de {pageNumbers}
              </Text>
              <Button onClick={goToFirstPage} disabled={currentPage === 1 || pageNumbers == 0} color={'#767676'} bgColor="transparent">
                <Icon as={MdOutlineArrowBackIos} fontSize="20px"/>
              </Button>
              <Button onClick={goToPreviousPage} disabled={currentPage === 1 || pageNumbers == 0 } color={'#767676'} bgColor="transparent">
              <Icon as={MdOutlineArrowBackIos} fontSize="15px" />
              </Button>

              <Button onClick={goToNextPage} disabled={currentPage === pageNumbers || pageNumbers == 0} color={'#767676'} bgColor="transparent">
              <Icon as={MdOutlineArrowBackIos} fontSize="15px" transform="rotate(180deg)"/>
              </Button>
              <Button onClick={goToLastPage} disabled={currentPage === pageNumbers || pageNumbers == 0} color={'#767676'} bgColor="transparent">
              <Icon as={MdOutlineArrowBackIos} fontSize="20px" transform="rotate(180deg)" />
              </Button>
            </HStack>
          </Stack>
        </Flex>
      </GridItem>
    </>
  );
};

export default PageTable;
