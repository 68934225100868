import { Event } from '../types'

export default {
  getCalendar: (): { data: Event[] } => ({
    data: [
      {
        id: '1',
        day: '1',
        title: 'Noticias, Secretaria de Saúde',
        time: '18:00'
      },
      {
        id: '2',
        day: '24',
        title: 'Noticias, Secretaria de Saúde',
        time: '18:00'
      },
      {
        id: '3',
        day: '24',
        title: 'HHH',
        time: '18:00'
      }
    ]
  })
}
